import { convertValueToNumber } from '../utilities/toolkit.js';

export default {
	// main function for requesting mode re-render
	_requestModeRender({
		trackPosition = this.trackAnimator.currentPos,
		trackDelta = 0,
		velocity = 0,
		progress,
		animationType = 'jump',
	}) {
		this.mode.render({
			slides: this.slides,
			trackPosition,
			trackDelta,
			velocity,
			progress,
			animationType,
		});
	},

	_calculateWidths() {
		const _ = this;
		const options = _.options;
		const viewportWidth = _.viewport.offsetWidth;

		// console.log('calculate widths', viewportWidth);

		// calculate viewportWidth
		_.viewportWidth = viewportWidth;

		// calculate gap width
		_.gapWidth = convertValueToNumber(_.options.gap, viewportWidth);

		// padding left and right width
		_.paddingLeftWidth = convertValueToNumber(options.paddingLeft, viewportWidth);
		_.paddingRightWidth = convertValueToNumber(options.paddingRight, viewportWidth);

		// totalSlideWidth = viewportWidth - totalGapWidth - totalPaddingWidth
		const totalPaddingWidth = _.paddingLeftWidth + _.paddingRightWidth;
		const totalGapWidth = _.gapWidth * (options.slidesVisible - 1);
		const totalSlideWidth = viewportWidth - totalGapWidth - totalPaddingWidth;

		// calculate slide width
		// _.slideWidth = totalSlideWidth / options.slidesVisible;
		_._setSlideWidth(totalSlideWidth / options.slidesVisible);

		// calculate slide min width
		_.slideMinWidth = convertValueToNumber(options.slideMinWidth, _.slideWidth);

		// TODO: Should we use setters for all of these properties?
		// track width
		const trackWidth = _.slides.length * (_.slideWidth + _.gapWidth);
		// _.trackWidth = trackWidth;
		_._setTrackWidth(trackWidth);

		// do this anytime widths change
		_._calculatePageCount();
	},

	_calculatePageCount() {
		const _ = this;
		// save page count
		_.pageCount = Math.ceil(_.slides.length / _.options.slidesPerMove);
	},

	_setDisplayIndex({ newIndex, velocity = 0, animationType = 'animate' }) {
		const _ = this;
		const oldIndex = _.displayIndex;

		// keep value within min and max range
		if (newIndex < 0) {
			newIndex = 0;
		} else if (newIndex >= _.slides.length) {
			newIndex = _.slides.length - 1;
		}

		// exit if they're the same
		if (oldIndex === newIndex) return;

		// update display index on carousel object
		_.displayIndex = newIndex;

		// emit change
		// this will trigger the mode to transition to the new slide
		_.emit('displayIndexChanged', { oldIndex, newIndex, velocity, animationType });
	},

	/**
	 * sets the current page and emits an event
	 * (removed velocity and animate parameters)
	 * @param {number} newPage - the new page to set
	 */
	_setPage(newPage) {
		// keep value positive
		if (newPage < 0) return;

		const oldPage = this.page;

		// exit if pages are the same
		if (newPage === oldPage) return;

		// set page to new value and announce the change
		this.page = newPage;
		this.emit('pageChanged', { oldPage, newPage });
	},

	_setSlideWidth(newWidth) {
		const _ = this;

		// keep value positive
		if (newWidth < 0) return;

		const oldWidth = _.slideWidth;

		// exit if nothing changing
		if (newWidth === oldWidth) return;

		// save new value and emit change
		_.slideWidth = newWidth;
		// set slide width in DOM and emit change
		_.style.setProperty('--tarot-slide-width', `${newWidth}px`);
		_.emit('slideWidthChanged', { oldWidth, newWidth });
	},

	_setTrackWidth(newWidth) {
		const _ = this;

		// keep value positive
		if (newWidth < 0) return;

		const oldWidth = _.trackWidth;

		// exit if nothing changing
		if (newWidth === oldWidth) return;

		// set new track width
		_.trackWidth = newWidth;
		// set width in DOM and emit change
		_.track.style.width = `${_.trackWidth}px`;
		_.emit('trackWidthChanged', { oldWidth, newWidth });
	},

	_shiftTrackForwards() {
		this.trackAnimator.shiftTrack(-1);
	},

	_shiftTrackBackwards() {
		this.trackAnimator.shiftTrack();
	},

	_dragStart(event, drag) {
		this.trackAnimator.dragStart();
	},

	_dragMove(event, drag) {
		this.trackAnimator.dragMove(event, drag);
	},

	_dragEnd(event, drag) {
		const _ = this;

		// drag didn't reach threshold - reset track
		if (Math.abs(drag.delta) < _.options.dragThreshold) {
			// TODO: figure this out
			// _.animateToSlide(_.carousel.displayIndex, 0);
			_.trackManager.settleTrack();
			return;
		}

		// trigger prev or next movement
		if (drag.delta < 0) {
			_.next(drag.velocity);
		} else {
			_.prev(drag.velocity);
		}
	},
};
