import EventEmitter from './event-emitter.js';

export default class PhysicsEngine {
	#attraction;
	#friction;
	#frictionFactor;
	#velocity;
	#currentValue;
	#targetValue;
	#isAnimating;
	#prevTime;
	#eventEmitter;
	#rafId; // we'll store the requestanimationframe id
	#accumulatedTime;
	#animationId;

	/**
	 * creates an instance of physicsengine.
	 * @param {number} [attraction=0.026] - the attraction value for physics-based animation (0 < attraction < 1).
	 * @param {number} [friction=0.28] - the friction value for physics-based animation (0 < friction < 1).
	 */
	constructor({ attraction = 0.026, friction = 0.28 } = {}) {
		const _ = this;
		_.#validateAttraction(attraction);
		_.#validateFriction(friction);

		_.#attraction = attraction;
		_.#friction = friction;
		_.#frictionFactor = 1 - friction;

		_.#velocity = 0;
		_.#currentValue = 0;
		_.#targetValue = 0;

		_.#isAnimating = false;
		_.#prevTime = null;
		_.#rafId = null; // no rAF scheduled yet
		_.#animationId = 0; // start at zero

		_.#eventEmitter = new EventEmitter();
	}

	/**
	 * animates from a start value to an end value.
	 * @param {number} startValue - the starting value.
	 * @param {number} endValue - the target value.
	 * @param {number} initialVelocity - the initial velocity.
	 */
	animateTo(startValue, endValue, initialVelocity) {
		const _ = this;

		// if something is already animating, stop it first
		if (_.#isAnimating) {
			_.stop();
		}

		if (isNaN(endValue)) {
			console.log('end value is not a number', _.carousel);
			return;
		}

		// increment #animationId to mark this as our "active" animation
		const localAnimationId = ++_.#animationId;

		const totalDistance = endValue - startValue;
		const totalDistanceAbs = Math.max(Math.abs(totalDistance) - 230, 1);

		// apply a velocity boost
		initialVelocity *= 1.4;

		_.#currentValue = startValue;
		_.#targetValue = endValue;
		_.#velocity = initialVelocity;
		_.#isAnimating = true;
		_.#prevTime = null;

		/**
		 * internal animation loop.
		 * @param {number} time - the timestamp from requestanimationframe
		 */
		const animate = (time) => {
			// exit if this is not the active animation ID
			if (!_.#isAnimating || _.#animationId !== localAnimationId) {
				return;
			}

			// figure out how much time has passed
			// min timeDelta value is 8.33 becaues that's 120hz displays
			const timeDelta = _.#prevTime == null ? 8.33 : time - _.#prevTime;

			// save current time to prevTime
			_.#prevTime = time;

			// figure out ratio based on total distance (the user wants 300px reference)
			let ratio = totalDistanceAbs / 200;
			// clamp within range
			ratio = Math.max(0.8, Math.min(ratio, 1.3));

			// compute the actual time factor
			const timeDeltaFactor = timeDelta / (13 * ratio);

			// calculate force based on distance to target and attraction
			const displacement = _.#targetValue - _.#currentValue;
			const force = displacement * _.#attraction;

			// apply force to velocity
			_.#velocity += force * timeDeltaFactor;
			// apply friction based on time between frames
			_.#velocity *= Math.pow(_.#frictionFactor, timeDeltaFactor);

			// calculate amount for this move
			const posDelta = _.#velocity * timeDeltaFactor;
			// apply move to current value
			_.#currentValue += posDelta;

			// figue our total distance covered
			const distanceCovered = _.#currentValue - startValue;
			// progress percent
			let progress = totalDistance !== 0 ? distanceCovered / totalDistance : 0;

			// check if we've arrived at target
			if (Math.abs(posDelta) < 0.01 && Math.abs(_.#currentValue - _.#targetValue) < 0.1) {
				_.#isAnimating = false;
				// set current value to target
				_.#currentValue = _.#targetValue;
				// emit last change event with final values
				_.#eventEmitter.emit('enginePositionChanged', {
					position: _.#currentValue,
					positionDelta: 0,
					progress: 1,
					velocity: 0,
				});
				// emit animation finished event
				_.#eventEmitter.emit('animationFinished');
				return;
			}

			// otherwise emit a position change event
			_.#eventEmitter.emit('enginePositionChanged', {
				position: _.#currentValue,
				positionDelta: posDelta,
				progress,
				velocity: _.#velocity,
			});

			// schedule the next frame
			_.#rafId = requestAnimationFrame(animate);
		};

		// start the loop
		_.#rafId = requestAnimationFrame(animate);
	}

	/**
	 * stops the ongoing animation immediately.
	 */
	stop() {
		this.#isAnimating = false;

		// if we already scheduled a frame, cancel it
		if (this.#rafId != null) {
			cancelAnimationFrame(this.#rafId);
			this.#rafId = null;
		}
	}

	/**
	 * returns whether we are currently animating.
	 * @returns {boolean}
	 */
	isAnimating() {
		return this.#isAnimating;
	}

	/**
	 * sets the attraction value
	 * @param {number} attraction - must be a number between 0 and 1 (exclusive).
	 */
	setAttraction(attraction) {
		this.#validateAttraction(attraction);
		this.#attraction = attraction;
	}

	/**
	 * sets the friction value
	 * @param {number} friction - must be a number between 0 and 1 (exclusive).
	 */
	setFriction(friction) {
		this.#validateFriction(friction);
		this.#friction = friction;
		this.#frictionFactor = 1 - friction;
	}

	/**
	 * adds an event listener for the specified event.
	 * @param {string} eventName - the name of the event.
	 * @param {function} eventFunction - the function to call when the event is triggered.
	 */
	on(eventName, eventFunction) {
		this.#eventEmitter.on(eventName, eventFunction);
	}

	/**
	 * remove an event listener for the specified event.
	 * @param {string} eventName - the name of the event.
	 * @param {function} eventFunction - the function to remove
	 */
	off(eventName, eventFunction) {
		this.#eventEmitter.off(eventName, eventFunction);
	}

	#validateAttraction(attraction) {
		if (typeof attraction !== 'number' || attraction <= 0 || attraction >= 1) {
			throw new Error('Attraction must be a number between 0 and 1 (exclusive).');
		}
	}

	#validateFriction(friction) {
		if (typeof friction !== 'number' || friction <= 0 || friction >= 1) {
			throw new Error('Friction must be a number between 0 and 1 (exclusive).');
		}
	}

	destroy() {
		// stop any ongoing animation
		this.stop();

		this.#eventEmitter.destroy();
		// clear event emitter reference
		this.#eventEmitter = null;
	}
}
