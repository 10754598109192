import { deepMerge } from '../utilities/toolkit.js';

/** @class OptionsManager - Manages carousel options and responsive breakpoints */

/*
	potential renames: 

	// how many to show
	slidesVisible - favorite
	slidesPerView

	// how many to move
	slidesPerGroup
	slidesPerPage
	slidesPerMove - favorite
*/

export default class OptionsManager {
	/**
	 * Creates a new OptionsManager instance
	 * @param {Object} carousel - The carousel instance this manager belongs to
	 */
	constructor(carousel) {
		const _ = this;
		/** @type {Object} - Reference to parent carousel */
		_.carousel = carousel;

		/** @type {Object} - Default carousel options */
		_.defaultOptions = {
			/** @type {boolean|string} - Selector for carousel to sync with */
			asNavFor: false,

			/** @type {Object} - Physics-based animation settings */
			animation: {
				/** @type {number} - Spring attraction coefficient */
				attraction: 0.026,
				/** @type {number} - Friction coefficient for dampening */
				friction: 0.24,
				/** @type {number} - Base animation speed */
				speed: 5,
				/** @type {number} - Multiplier for initial velocity */
				velocityBoost: 1.1,
			},

			/** @type {number} - Deprecated: Autoplay interval in ms (use autoplay object instead) */
			autoplay: 0,
			/** @type {string} - What happens to autoplay after user interaction */
			autoplayAfterInteraction: 'pause', // stop or pause

			/** @type {Object} - Autoplay settings */
			autoplay: {
				/** @type {number} - Time between slides in ms (0 = disabled) */
				interval: 0,
				/** @type {boolean} - Whether to stop autoplay after user interaction */
				stopAfterInteraction: true,
			},

			/** @type {Object} - Responsive breakpoint settings */
			breakpoints: {},

			/** @type {string} - Uses either "viewport" or "window" for breakpoints */
			breakpointElement: 'window',

			centerSelectedSlide: false,

			/** @type {number} - Minimum drag distance to trigger slide change */
			dragThreshold: 40,

			/** @type {string} - Class to filter which slides are included */
			filterClass: '',
			/** @type {boolean} - Whether clicking a slide selects it */
			focusOnSelect: false,
			/** @type {number} - Starting slide index */
			initialIndex: 0,
			/** @type {boolean} - Whether carousel should loop */
			loop: true,
			/** @type {string} - Display mode ('carousel', 'fade', etc) */
			mode: 'carousel',

			/** @type {number} - Gap between slides (px) */
			gap: 0,
			/** @type {number} - Left padding (px) */
			paddingLeft: 0,
			/** @type {number} - Right padding (px)  */
			paddingRight: 0,
			/** @type {string} - Min width for slides */
			slideMinWidth: '50px',
			/** @type {number} - Slides visible at once */
			slidesVisible: 1,
			/** @type {number} - Slides to move on navigation */
			slidesPerMove: 1,

			/** @type {Object} - Navigation controls settings */
			navigation: {
				/** @type {boolean} - Whether to show navigation buttons */
				showButtons: true,
				/** @type {boolean} - Whether to show previous button */
				showPrevButton: true,
				/** @type {boolean} - Whether to show next button */
				showNextButton: true,
				/** @type {boolean|string} - Custom selector for prev button */
				prevButtonSelector: false,
				/** @type {boolean|string} - Custom selector for next button */
				nextButtonSelector: false,
				/** @type {boolean} - Whether buttons should hide when navigation limit reached */
				smartButtons: false,

				/** @type {boolean} - Whether to show pagination */
				showPagination: true,
				/** @type {boolean|string} - Custom selector for pagination container */
				paginationSelector: false,
			},

			goToSelectedSlide: false,

			/** @type {boolean|string} - Selector for carousel to sync with */
			syncWith: false,
		};
		/** @type {Object} - User-provided options */
		_.userOptions = {};

		/** @type {HTMLElement|null} - Element containing data-tarot-options JSON */
		_.userOptionsElement = _.carousel.querySelector(':scope > [data-tarot-options]');

		/** @type {Object} - Current active breakpoint */
		_.currentBreakpoint = { minWidth: 0, options: {} };

		/** @type {Object} - Previous active breakpoint */
		_.prevBreakpoint = { minWidth: 0, options: {} };

		/** @type {Object} - Merged options (default + user + breakpoint) */
		_.options = {};

		// define all event handlers in one object
		_.handlers = {
			// handler for windowResize event
			windowResize: () => {
				_.checkBreakpoints();
			},
		};

		_.init();
	}

	/** Initialize the options manager */
	init() {
		const _ = this;

		_.bindEvents();

		// load user options
		_.loadUserOptions();
		// get current breakpoint options
		_.currentBreakpoint = _.calculateCurrentBreakpoint();

		// prev breakpoint is equal to current breakpoint
		_.prevBreakpoint = { ..._.currentBreakpoint };
		// load merged options
		_.loadMergedOptions();
	}

	/** Re-check breakpoints and update options */
	reInit() {
		this.checkBreakpoints();
	}

	/** Bind event listeners for responsive behavior */
	bindEvents() {
		const _ = this;
		_.carousel.on('windowResize', _.handlers.windowResize);
	}

	/** Load user-provided options from DOM or programmatic settings */
	loadUserOptions() {
		const _ = this;

		// no user options to load
		if (!_.userOptionsElement) return;

		// get content and remove new lines
		let optionsText = _.userOptionsElement.textContent.split('\n').join('').trim();
		// add quotes around keys that don't have them
		optionsText = optionsText.replace(/(\w+:)|(\w+ :)/g, function (s) {
			return `"${s.substring(0, s.length - 1)}":`;
		});

		try {
			_.userOptions = JSON.parse(optionsText);
		} catch (error) {
			console.error('Error parsing user options:', error);
		}
	}

	/** Merge defaults, user, and breakpoint options together */
	loadMergedOptions() {
		const _ = this;
		const options = _.carousel.getOptions();

		// merge new options
		let newOptions = deepMerge(_.defaultOptions, _.userOptions);
		newOptions = deepMerge(newOptions, _.currentBreakpoint.options);

		// breakpoints not important here
		delete newOptions.breakpoints;

		// save back to carousel options
		_.options = newOptions;
		_.carousel.setOptions(newOptions);
	}

	/**
	 * Set new options for the carousel.
	 * @param {Object} newOptions - The new options to set.
	 * @returns {OptionsManager} - Returns the options manager for chaining.
	 */
	updateOptions(newOptions) {
		const _ = this;

		// Ensure slidesPerMove and slidesVisible are >= 1
		if (newOptions.slidesPerMove < 1) newOptions.slidesVisible = 1;
		if (newOptions.slidesVisible < 1) newOptions.slidesPerMove = 1;

		// Merge new options into user options using deep merge
		_.userOptions = deepMerge(_.userOptions, newOptions);

		// remerge all options
		_.loadMergedOptions();

		// Calculate active breakpoint based on new options
		_.checkBreakpoints();

		return _;
	}

	/**
	 * Check current viewport width against breakpoints and update options if needed
	 */
	checkBreakpoints() {
		const _ = this;
		const activeBreakpoint = _.calculateCurrentBreakpoint();

		// if no active breakpoint or if it hasn't changed
		if (!activeBreakpoint || activeBreakpoint.minWidth === _.currentBreakpoint.minWidth) return;

		const prevOptions = { ..._.options };
		_.prevBreakpoint = _.currentBreakpoint;
		_.currentBreakpoint = activeBreakpoint;
		_.loadMergedOptions();

		// make sure slidesVisible != 0
		// TODO: check user options for valid inputs
		if (_.options.slidesVisible <= 0) {
			_.options.slidesVisible = _.options.slidesVisible;
		}
	}

	/**
	 * Determine which breakpoint applies based on current window width
	 * @returns {Object} The active breakpoint object with minWidth and options
	 */
	calculateCurrentBreakpoint() {
		const _ = this;
		let curentwidth;
		const breakpoints = _.userOptions.breakpoints;

		// get width or viewport or window
		if (_.carousel.options.breakpointElement == 'viewport' && _.carousel.viewport) {
			curentwidth = _.carousel.viewport.offsetWidth;
		} else {
			curentwidth = window.innerWidth;
		}

		// return empty breakpoint if no breakpoints exist
		if (!breakpoints || typeof breakpoints !== 'object') {
			return { minWidth: 0, options: {} };
		}

		// Get breakpoint keys as numbers
		// these are already sorted form smallest to largest
		const breakpointWidths = Object.keys(breakpoints).map(Number);

		// Find the largest breakpoint that is less than or equal to breakpointWidth
		let matchingBreakpointWidth = 0;

		for (let i = 0; i < breakpointWidths.length; i++) {
			const breakpointWidth = breakpointWidths[i];
			// check to see if we're still in the range
			if (curentwidth >= breakpointWidth) {
				// we have a winner, so save it
				matchingBreakpointWidth = breakpointWidth;
			} else {
				// exit if we're past our width
				break;
			}
		}

		// return the matching options
		return {
			minWidth: matchingBreakpointWidth,
			options: breakpoints[matchingBreakpointWidth] || {},
		};
	}

	/**
	 * Clean up event listeners
	 */
	destroy() {
		const _ = this;
		_.carousel.off('windowResize', _.handlers.windowResize);
	}
}
