import PhysicsEngine from '../library/physics-engine.js';

// Manages track animations using physics-based motion
export default class TrackAnimator {
	#eventEmitter;
	#currentPos = 0;
	#dragStartPos = 0;
	#minVelocity = 0;
	#targetPos = 0;
	#animationType = '';

	// @param {TarotCarousel} carousel - The parent carousel instance
	constructor(carousel) {
		const _ = this;
		_.carousel = carousel;
		_.track = carousel.track;

		// load new physics engine with attraction and friction options
		_.engine = new PhysicsEngine({
			attraction: _.carousel.options.animation.attraction,
			friction: _.carousel.options.animation.friction,
		});

		_.#dragStartPos = 1;

		_.bindEvents();
	}

	init() {}

	reInit() {}

	bindEvents() {
		const _ = this;

		_.carousel.on('optionsChanged', () => {
			_.engine.setAttraction(_.carousel.options.animation.attraction);
			_.engine.setFriction(_.carousel.options.animation.friction);
		});

		// Set up event listeners for engine events
		// { trackPosition, trackDelta, velocity, progress, animationType }
		_.engine.on('enginePositionChanged', ({ position, positionDelta, progress, velocity }) => {
			if (progress === 1) {
				// we have reached the end
				_.setPos(_.#targetPos, progress, velocity, _.#animationType);
			} else {
				// add delta to current position
				_.setPos(_.#currentPos + positionDelta, progress, velocity, _.#animationType);
			}
		});

		_.engine.on('animationFinished', () => {
			// relay finished event to carousel
			_.carousel.emit('animationFinished', _.#targetPos);
		});
	}

	// @returns {number} - The current X position.
	get currentPos() {
		return this.#currentPos;
	}

	getIsAnimating() {
		return this.engine.isAnimating();
	}

	// drag events
	dragStart() {
		this.stop();
		this.#dragStartPos = this.#currentPos;
	}

	dragMove(event, drag) {
		this.stop();
		this.setPos(this.#dragStartPos + drag.delta, 1, null, 'drag');
	}

	stop() {
		this.engine.stop();
	}

	animateToPosition(targetPos, velocity, animationType) {
		const _ = this;

		_.stop();

		// save animation type forlater
		_.#animationType = animationType;

		if (animationType === 'jump') {
			this.setPos(targetPos, 1, 0, 'jump');
			return;
		}

		// don't animate if we're already going to that position
		if (_.engine.isAnimating() && _.#targetPos == targetPos) {
			return;
		}

		// calculate min velocity
		if (!velocity) {
			if (targetPos < _.#currentPos) {
				velocity = _.#minVelocity * -1;
			} else {
				velocity = _.#minVelocity;
			}
		}

		// save target position
		_.#targetPos = targetPos;

		// tell engine to go to target with velocity
		_.engine.animateTo(_.#currentPos, targetPos, velocity);
	}

	// jumpToPosition(position, animationType) {
	// 	this.stop();
	// }

	// type = 'animate', 'jump', settle
	setPos(newPosition, progress, velocity = 0, animationType) {
		const _ = this;
		let trackDelta = newPosition - _.#currentPos;
		const oldPosition = _.#currentPos;

		// final delta is 0
		if (animationType !== 'drag' && progress == 1) {
			trackDelta = 0;
		}

		// save new position
		_.#currentPos = newPosition;

		// save to carousel
		_.carousel.trackPosition = newPosition;

		// set DOM element to target position
		if (progress >= 1) {
			// settle with translateX
			_.track.style.transform = `translateX(${_.#currentPos}px)`;
		} else {
			// animate with translate3d
			_.track.style.transform = `translate3d(${_.#currentPos}px,0,0)`;
		}

		// TODO: move to _setTrackPosition

		// request mode re-render
		_.carousel._requestModeRender({
			oldPosition,
			trackPosition: newPosition,
			trackDelta,
			velocity,
			progress: progress,
			animationType,
		});

		// emit position changed - used by sync-with
		_.carousel.emit('trackPositionChanged', {
			oldPosition,
			newPosition,
			trackDelta,
			progress,
			animationType,
		});

		// console.log('*** Track Animator requesting render: ', {
		// 	oldPosition,
		// 	trackPosition: newPosition,
		// 	trackDelta: delta,
		// 	velocity,
		// 	progress: progress,
		// 	animationType: _.#animationType,
		// });
	}

	// value = -1 for forwards, 1 for backwards
	shiftTrack(value = 1) {
		const _ = this;
		const rectWidth = _.track.getBoundingClientRect().width;
		// console.log('shift track by: ' + rectWidth);

		// shift track by full length
		_.#currentPos += rectWidth * value;
		_.#dragStartPos += rectWidth * value;
		_.#targetPos += rectWidth * value;

		// console.log(
		// 	'\n%%%%%%%%--          SHIFTING TRACK                --%%%%%%%%  newPos',
		// 	_.#currentPos
		// );

		// move track but don't emit any events
		_.track.style.transform = `translateX(${_.#currentPos}px)`;

		// tell mode to re-render with new position
		_.carousel._requestModeRender({
			trackPosition: _.#currentPos,
			animationType: 'shift',
		});
	}

	destroy() {
		this.engine = null;
		this.#eventEmitter = null;
	}
}
