import EventEmitter from '../library/event-emitter.js';

/**
 * this will sync with the other carousel when the displayIndex changes
 */
export default class SyncWith {
	/**
	 * @constructor
	 * @param {Object} carousel - the carousel instance that will be synced with another
	 */
	constructor(carousel) {
		const _ = this;
		_.carousel = carousel;
		_.otherCarousel = null;

		// define all event handlers in one object
		_.handlers = {
			// handler for other carousel's willTransition event
			otherWillTransition: ({ oldIndex, newIndex, velocity, animate }) => {
				// return if we are already going to that index or already at it
				if (_.carousel.displayIndex === newIndex) return;

				// go to same display index as other carousel
				_.carousel.goToSlide(newIndex);
			},

			// handler for carousel's optionsChanged event
			optionsChanged: (data) => {
				// console.log('SyncWith: optionsChanged', data);
				// check options to see if we still have SyncWith
				// disconnect or connect to new carousel
				_.reInit();
			},

			// handler for carousel's displayIndexChanged event
			displayIndexChanged: ({ oldIndex, newIndex }) => {
				// console.log('SyncWith: displayIndexChanged', { oldIndex, newIndex });
				// tell other carousel to go to this index
				_.updateSyncWithCarousel();
			},
		};

		_.init();
		_.bindEvents();
	}

	/**
	 * initialize the sync functionality
	 */
	init() {
		const _ = this;
		const otherCarouselId = _.carousel.options.syncWith;

		// exit if no value
		if (!otherCarouselId) return;

		// get syncWith value from options
		_.otherCarousel = document.querySelector(_.carousel.options.syncWith);

		// can't find it
		if (_.otherCarousel === undefined) return;

		// give DOM a few ms to load the custom elements
		setTimeout(() => {
			_.otherCarousel.on('willTransition', _.handlers.otherWillTransition);
		}, 100);
	}

	/**
	 * reinitialize on carousel option changes
	 */
	reInit() {
		// implementation as needed
		// console.log('reinit SyncWith');
	}

	/**
	 * bind carousel events to handlers
	 */
	bindEvents() {
		const _ = this;
		// bind to carousel events
		_.carousel.on('optionsChanged', _.handlers.optionsChanged);
		_.carousel.on('displayIndexChanged', _.handlers.displayIndexChanged);
	}

	/**
	 * update the other carousel to match this one
	 */
	updateSyncWithCarousel() {
		const _ = this;
		// return if we don't have a carousel to update
		if (!_.otherCarousel) return;
		// console.log('_.otherCarousel is', _.otherCarousel);
		// tell carousel to go to the same display index
		_.otherCarousel.goToSlide(_.carousel.displayIndex);
	}

	/**
	 * destroy the sync, unbinding all events
	 */
	destroy() {
		const _ = this;

		// unbind from carousel events
		_.carousel.off('optionsChanged', _.handlers.optionsChanged);
		_.carousel.off('displayIndexChanged', _.handlers.displayIndexChanged);

		// unbind from other carousel events
		if (_.otherCarousel) {
			_.otherCarousel.off('willTransition', _.handlers.otherWillTransition);
		}

		_.otherCarousel = null;
	}
}
