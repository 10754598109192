import DragHandler from './core/drag-handler.js';
import EventEmitter from './library/event-emitter.js';
import EventManager from './core/event-manager.js';
import LoopManager from './core/loop-manager.js';
import ModeManager from './core/mode-manager.js';
import OptionsManager from './core/options-manager.js';
import SlideManager from './core/slide-manager.js';
import TrackAnimator from './core/track-animator.js';
import TrackManager from './core/track-manager.js';
import WindowEvents from './core/window-events.js';

/** 🔮 ✨ 🕯️ 🍄 🌙 ⭐ TarotCarousel ⭐ 🌙 🍄 🕯️ ✨ 🔮 */
export default class TarotCarousel extends HTMLElement {
	/** @type {Array} - Registered plugins that will be initialized with new carousel instances */
	static plugins = [];

	/** @type {number} - Count of carousel instances created */
	static instanceCount = 0;

	// private manager instances
	#trackManager;
	#trackAnimator;
	#slideManager;
	#optionsManager;
	#modeManager;
	#dragHandler;
	#eventManager;
	#loopManager;
	#windowEvents;
	#events;

	/**
	 * Register a plugin class to be initialized later.
	 * @param {Function} plugin - The plugin class to be registered.
	 * @returns {TarotCarousel} - Returns the carousel instance for chaining.
	 */
	static use(plugin) {
		const _ = this;
		if (!_.plugins) {
			_.plugins = [];
		}
		_.plugins.push(plugin);
		return _;
	}

	/**
	 * Creates a new TarotCarousel instance
	 * @constructor
	 */
	constructor() {
		super();
		const _ = this;

		// set a unique id for this carousel instance
		this.#setCarouselId();

		/** @type {Array} - Holds instantiated plugin instances */
		_.pluginInstances = [];

		/** @type {boolean|Object} - Current active mode instance */
		_.mode = false;

		/** @type {Object} - Merged options from defaults and user settings */
		_.options = {};

		/** @type {number} - Current slide display index */
		_.displayIndex = 0;

		/** @type {number} - Current active page index */
		_.page = 0;

		/** @type {number} - Total number of pages */
		_.pageCount = 1;

		/** @type {HTMLElement} - Currently selected slide element */
		_.selectedIndex = 0;

		/** @type {number} - Current viewport width */
		_.viewportWidth = 0;

		/** @type {number} - Current track width */
		_.trackWidth = 0;

		/** @type {number} - Current slide width */
		_.slideWidth = 0;

		_.slideMinWidth = 0;

		/** @type {number} - Current gap width */
		_.gapWidth = 0;

		/** @type {number} - Current left padding width */
		_.paddingLeftWidth = 0;

		/** @type {number} - Current right padding width */
		_.paddingRightWidth = 0;

		_.trackPosition = 0;

		// carousel elements
		/** @type {HTMLElement} - The slide track element containing all slides */
		_.track = _.querySelector(':scope tarot-slides');

		/** @type {HTMLElement} - The viewport element defining the visible area */
		_.viewport = _.querySelector(':scope tarot-viewport');

		/** @type {Array<HTMLElement>} - Contains all active slides as a node list */
		_.slides = [];

		/** @type {HTMLElement|null} - Previous button element */
		_.prevButton = null;

		/** @type {HTMLElement|null} - Next button element */
		_.nextButton = null;

		// initialize all private objects
		_.#events = new EventEmitter();
		_.#optionsManager = new OptionsManager(_);
		_.#windowEvents = new WindowEvents(_);
		_.#slideManager = new SlideManager(_);
		_._calculateWidths();

		_.#dragHandler = new DragHandler(_);
		_.#trackAnimator = new TrackAnimator(_);
		_.#trackManager = new TrackManager(_, _.#trackAnimator);
		_.#eventManager = new EventManager(_);
		_.#loopManager = new LoopManager(_);
		_.#modeManager = new ModeManager(_);
	}

	/** set a unique id for this carousel if one doesn't exist */
	#setCarouselId() {
		const _ = this;
		// check if the carousel has an id, if not, assign one
		if (!_.id) {
			_.id = `tarot-carousel-${TarotCarousel.instanceCount}`;
		}

		// increment the static instance counter
		TarotCarousel.instanceCount++;
	}

	connectedCallback() {
		// wait for carousel to be fully connected to the DOM
		this.init();
	}

	// init the carousel and activate all registered plugins
	init() {
		const _ = this;

		// calcualte widths
		// _._calculateWidths();

		// registered plugins that will be created
		const plugins = _.constructor.plugins;

		// tell mode manager to load mode
		_.#modeManager.loadCurrentMode();

		// init all registered plugins
		plugins.forEach((PluginClass) => {
			// create and save all plugins
			const pluginInstance = new PluginClass(_);
			_.pluginInstances.push(pluginInstance);
		});

		// tell mode to do initial render
		// _._requestModeRender(0, 0, 0, 1, 'jump');

		// tell carousel to jump to initial slide
		_.jumpToSlide(_.options.initialIndex);
	}

	/**
	 * Reinitialize the carousel and plugins.
	 */
	reInit() {
		// Reinitialize the carousel logic here...
		this.#modeManager.reInit();

		// Call reInit on each active plugin if they implement it
		this.pluginInstances.forEach((plugin) => {
			plugin.reInit();
		});
	}

	/** access the event emitter @returns {EventEmitter} - the event emitter instance */
	get events() {
		return this.#events;
	}

	get trackAnimator() {
		return this.#trackAnimator;
	}

	/** access the track animator @returns {TrackAnimator} - the track animator instance */
	get animator() {
		return this.#trackAnimator;
	}

	/** access the slide manager @returns {SlideManager} - the slide manager instance */
	get slideManager() {
		return this.#slideManager;
	}

	/** access the options manager @returns {OptionsManager} - the options manager instance */
	get optionsManager() {
		return this.#optionsManager;
	}

	/** access the mode manager @returns {ModeManager} - the mode manager instance */
	get modeManager() {
		return this.#modeManager;
	}

	/** access the drag handler @returns {DragHandler} - the drag handler instance */
	get dragHandler() {
		return this.#dragHandler;
	}

	/** access the event manager @returns {EventManager} - the event manager instance */
	get eventManager() {
		return this.#eventManager;
	}

	/** access the loop manager @returns {LoopManager} - the loop manager instance */
	get loopManager() {
		return this.#loopManager;
	}

	get trackManager() {
		return this.#trackManager;
	}

	/** access the window events @returns {WindowEvents} - the window events instance */
	get windowEvents() {
		return this.#windowEvents;
	}

	/**
	 * Destroy the carousel and cleanup all plugins.
	 */
	destroy() {
		// call destroy on each plugin if they implement it
		this.pluginInstances.forEach((plugin) => {
			plugin.destroy();
		});

		// clear plugin instances
		this.pluginInstances = [];
	}
}
