/**
 * @class SlideManager
 * Manages slides in a carousel including slide creation, selection, filtering, and DOM updates
 */
export default class SlideManager {
	/**
	 * @constructor
	 * @param {Object} carousel - The parent carousel instance that owns this manager
	 */
	constructor(carousel) {
		const _ = this;
		/** @type {Object} - Reference to parent carousel */
		_.carousel = carousel;

		/** @type {NodeList|null} - Collection of original slides in the DOM */
		_.coreSlides = null;

		/** @type {Object} - Event handlers for slide changes */
		_.handlers = {
			// handler for carousel's selectedIndexChanged event
			selectedIndexChanged: ({ newIndex }) => {
				_.setSelectedIndex(newIndex);
			},

			// handler for DOM mutations
			domMutation: (mutationsList) => {
				for (const mutation of mutationsList) {
					if (mutation.type === 'childList') {
						// when a child is added or removed, reload the slides
						_.reInit();
						_.carousel.emit('slidesChanged');
					}
				}
			},
		};

		_.observer = null;
		_.bindEvents();
		_.init();
		_.observeDOMChanges();
	}

	/**
	 * Initialize slides by loading them from the DOM.
	 */
	init() {
		this.reInit();
	}

	/** Reinitialize the slide manager */
	reInit() {
		const _ = this;

		// make sure slides are wrapped in <tarot-slide>
		_.wrapSlides();

		// pull the core slides from the DOM on initial load
		_.coreSlides = _.carousel.track.querySelectorAll(':scope > tarot-slide');

		// apply filter to slides, this is an initial load
		_.loadFilteredSlides(true);

		// always update slide indexes
		// _.updateSlideIndexes();

		// set selected slide after they're loaded
		_.setSelectedIndex(_.carousel.selectedIndex);
	}

	/**
	 * bind carousel events to handlers
	 */
	bindEvents() {
		const _ = this;

		// bind to carousel events
		_.carousel.on('selectedIndexChanged', _.handlers.selectedIndexChanged);
	}

	/**
	 * Ensures all carousel children are properly wrapped in tarot-slide elements
	 * For content added directly to the track that isn't already in a slide wrapper
	 */
	wrapSlides() {
		const _ = this;
		const track = _.carousel.track;
		const childrenArray = Array.from(track.children);

		// Ensure all slides are wrapped in <tarot-slide> elements
		childrenArray.forEach((child) => {
			if (child.tagName.toLowerCase() !== 'tarot-slide') {
				// wrap in tarot-slide
				const wrapper = document.createElement('tarot-slide');
				track.replaceChild(wrapper, child);
				// place content in the slide
				wrapper.appendChild(child);
			}
		});
	}

	/**
	 * Sets up a MutationObserver to watch for DOM changes to slides
	 * Automatically reinitializes when slides are added or removed
	 */
	observeDOMChanges() {
		const _ = this;
		// create a new MutationObserver instance and pass a callback function
		_.observer = new MutationObserver(_.handlers.domMutation);

		// observe the target element (track) for changes to its children
		_.observer.observe(_.carousel.track, {
			childList: true, // listen for addition or removal of child elements
			subtree: false, // observe only the direct children of the track element
		});
	}

	/**
	 * Returns the current slide collection
	 * @returns {NodeList} Collection of slide elements
	 */
	getSlides() {
		return this.slides;
	}

	resetSlideIndexes(slides) {
		for (let i = 0, n = slides.length; i < n; ++i) {
			slides[i].setRenderIndex(i);
			slides[i].setAttribute('data-index', i);
		}
	}

	/**
	 * Load slides based on a filter class.
	 * @param {boolean} isInitialLoad - Whether this is the initial load.
	 */
	loadFilteredSlides(isInitialLoad = false) {
		const _ = this;
		const filterClass = _.carousel.options.filterClass;
		const coreSlides = _.coreSlides;
		const track = _.carousel.track;

		// set default render index on slides
		_.resetSlideIndexes(coreSlides);

		if (!filterClass) {
			// If filterClass is empty, use the original slides
			_.carousel.slides = coreSlides;
		} else {
			// Create a document fragment to hold the filtered slides
			const fragment = document.createDocumentFragment();

			// Filter slides based on the filterClass and add to the fragment
			coreSlides.forEach((slide) => {
				if (slide.classList.contains(filterClass)) {
					fragment.appendChild(slide.cloneNode(true));
				}
			});

			// Update slides to be a NodeList from the fragment
			_.carousel.slides = fragment.querySelectorAll('tarot-slide');

			// Clear the existing children of track and append the new slides
			track.innerHTML = '';
			track.appendChild(fragment);
		}

		if (!isInitialLoad) {
			// Take appropriate action if slides have changed
			if (_.carousel.plugin && _.carousel.plugin.reinitPlugin) {
				_.carousel.plugin.reinitPlugin();
			}
			// _.events.slidesChanged();
		}
	}

	/**
	 * Add a slide to the slides NodeList.
	 * @param {HTMLElement|string} element - The slide element or HTML string to add.
	 * @param {number} [index] - The index at which to add the slide. If not provided, the slide is added at the end.
	 */
	addSlide(element, index) {
		const _ = this;
		const track = _.carousel.track;

		// Check if element is an HTML string and convert to HTMLElement
		let newSlide;
		if (typeof element === 'string') {
			const tempDiv = document.createElement('div');
			tempDiv.innerHTML = `<tarot-slide>${element.trim()}</tarot-slide>`;
			newSlide = tempDiv.firstChild;
		} else {
			// Ensure the element is wrapped in a <tarot-slide> element
			const wrapper = document.createElement('tarot-slide');
			wrapper.appendChild(element);
			newSlide = wrapper;
		}

		// Add the slide at the specified index or at the end
		const slides = Array.from(_.carousel.slides);
		if (typeof index === 'number' && index >= 0 && index < slides.length) {
			track.insertBefore(newSlide, slides[index]);
		} else {
			track.appendChild(newSlide);
		}

		// Reload slides from DOM
		_.loadFilteredSlides();
	}

	/**
	 * Remove a slide from the slides NodeList at the specified index.
	 * @param {number} index - The index of the slide to remove.
	 */
	removeSlide(index) {
		const _ = this;
		const track = _.carousel.track;
		const slides = Array.from(_.coreSlides);

		// Check if index is valid
		if (index < 0 || index >= slides.length) {
			console.warn(`Index ${index} is out of bounds.`);
			return;
		}

		// remove child
		track.removeChild(slides[index]);

		// Reload slides
		_.loadFilteredSlides();
	}

	/**
	 * Update the data-index attribute for each slide.
	 */
	// updateSlideIndexes() {
	// 	const _ = this;
	// 	const slides = _.carousel.slides;
	// 	// Set the index for each slide
	// 	for (let i = 0, n = slides.length; i < n; ++i) {
	// 		slides[i].setAttribute('data-index', i);
	// 	}
	// }

	/**
	 * Set the selected slide and trigger necessary events.
	 * @param {number} newIndex - The new index of the selected slide.
	 */
	setSelectedIndex(newIndex) {
		const _ = this;
		// get all slides including clones
		const slides = _.carousel.slides;

		let slide;
		for (let i = 0, n = slides.length; i < n; ++i) {
			slide = slides[i];
			if (slide.dataset.index == newIndex) {
				// add class to selected slide
				slide.classList.add('tarot-selected');
			} else {
				// remove 'tarot-selected' from all other slides
				slide.classList.remove('tarot-selected');
			}
		}
	}

	/**
	 * destroy the slide manager, unbinding all events
	 */
	destroy() {
		const _ = this;

		// unbind from carousel events
		_.carousel.off('selectedIndexChanged', _.handlers.selectedIndexChanged);

		// disconnect the mutation observer
		if (_.observer) {
			_.observer.disconnect();
			_.observer = null;
		}

		_.coreSlides = null;
	}
}
