// styles
import './css/tarot-carousel.scss';

// main Tarot Carousel class
import TarotCarousel from './js/tarot-carousel.js';

// include custom HTML elements
import './js/core/custom-elements.js';

// import core
import publicMethods from './js/core/public-methods.js';
import privateMethods from './js/core/private-methods.js';

// Plugins
import AsNavFor from './js/plugins/as-nav-for.js';
import Autoplay from './js/plugins/autoplay.js';
import Buttons from './js/plugins/buttons.js';
import LazyLoad from './js/plugins/lazy-load.js';
import Pagination from './js/plugins/pagination.js';
import SyncWith from './js/plugins/sync-with.js';

// copy over functions to main class
Object.assign(TarotCarousel.prototype, privateMethods);
Object.assign(TarotCarousel.prototype, publicMethods);

// add plugins
TarotCarousel.use(AsNavFor);
TarotCarousel.use(Autoplay);
TarotCarousel.use(Buttons);
TarotCarousel.use(LazyLoad);
TarotCarousel.use(Pagination);
TarotCarousel.use(SyncWith);

// register as custom element
customElements.define('tarot-carousel', TarotCarousel);

export default TarotCarousel;
