import { areOptionsEqual } from '../utilities/toolkit.js';

export default {
	// basic carousel methods
	next(velocity) {
		this.goToPage(this.page + 1, velocity);
	},
	prev(velocity) {
		this.goToPage(this.page - 1, velocity);
	},

	/**
	 * goes to a specific slide by index
	 * @param {number} index - the index of the slide to go to
	 * @param {number} [velocity=0] - velocity to indicate direction or momentum
	 * @throws {Error} if the index is outside the valid range and looping is disabled
	 */
	goToSlide(index, velocity = 0) {
		const _ = this;
		const slideCount = _.slides.length;

		// make sure we're in range
		if (index < 0) {
			if (_.options.loop) {
				// if we are looping, go to last slide
				index += slideCount;
			} else {
				// if we aren't looping, clamp to 0
				index = 0;
				if (!velocity) velocity = 10; // bump to show end of track
			}
		} else if (index >= slideCount) {
			if (_.options.loop) {
				// if we are looping go back to first slide
				index = index % slideCount;
			} else {
				// if we aren't looping, clamp to last
				index = slideCount - 1;
				if (!velocity) velocity = -10; // bump to show end of track
			}
		}

		// set display index
		_._setDisplayIndex({ newIndex: index, velocity });

		_.trackManager.animateToSlide(index, velocity, 'animate');

		// update the page
		_._setPage(_.getPageIndexForSlide(index));
	},

	/**
	 * jumps immediately (no animation) to a specific slide
	 * @param {number} index - the index of the slide to jump to
	 * @param {number} [velocity=0] - velocity (not really used when jumping)
	 * @throws {Error} if the index is outside the valid range
	 */
	jumpToSlide(index) {
		const _ = this;
		const length = _.slides.length - 1;

		// throw an error if the requested index is outside the valid range
		if (index < 0 || index > length) {
			throw new Error(`Slide index ${index} is out of bounds. Valid range is 0 to ${length}.`);
		}

		// set display index
		_._setDisplayIndex({ newIndex: index, velocity: 0, animationType: 'jump' });

		_.trackManager.animateToSlide(index, 0, 'jump');

		// update the page
		// it's possible the page changes without
		// the displayIndex changing if there was a
		// change in slides visible
		_._setPage(_.getPageIndexForSlide(index));
	},

	/**
	 * goes to a specific page number
	 * @param {number} newPage - the page to go to
	 * @param {number} [velocity=0] - velocity to indicate direction or momentum
	 */
	goToPage(newPage, velocity = 0) {
		const _ = this;

		// stay within bounds and loop
		if (newPage < 0) {
			if (_.options.loop) {
				// if we are looping, go to last page
				newPage += _.pageCount;
			} else {
				// if we aren't looping, return to 0
				newPage = 0;
				// bump animation to show end of track
				if (!velocity) velocity = 10;
			}
		} else if (newPage >= _.pageCount) {
			if (_.options.loop) {
				// if we are looping go back to first page
				newPage = 0;
			} else {
				// if we aren't looping, stay at end of track
				newPage = _.pageCount - 1;
				// bump animation to show end of track
				if (!velocity) velocity = -10;
			}
		}

		// find the appropriate slide index for this page
		const newIndex = newPage * _.options.slidesPerMove;

		// call goToSlide with the velocity
		_.goToSlide(newIndex, velocity);
	},

	/**
	 * jumps immediately (no animation) to a specific page
	 * @param {number} newPage - the page to jump to
	 * @param {number} [velocity=0] - velocity (mostly unused here, default 0)
	 */
	jumpToPage(newPage, velocity = 0) {
		const _ = this;

		// stay within bounds and loop
		if (newPage < 0) {
			if (_.options.loop) {
				newPage += _.pageCount;
			} else {
				newPage = 0;
			}
		} else if (newPage >= _.pageCount) {
			if (_.options.loop) {
				newPage = 0;
			} else {
				newPage -= _.pageCount;
			}
		}

		// find the appropriate slide index for this page
		const newIndex = newPage * _.options.slidesPerMove;

		// call jumpToSlide
		_.jumpToSlide(newIndex, velocity);
	},

	/**
	 * returns the page index for a given slide index
	 * @param {number} slideIndex - the index of the slide
	 * @returns {number} the page index
	 */
	getPageIndexForSlide(slideIndex) {
		return Math.floor(slideIndex / this.options.slidesPerMove);
	},

	slideClicked(event) {
		const _ = this;

		// get slide
		const slide = event.target.closest('tarot-slide');
		// exit if no slide
		if (!slide) return;

		// get index
		const index = parseInt(slide.dataset.index);

		// emit event
		_.emit('slideClicked', { index, slide });

		// set new value
		_.setSelectedIndex(index);
	},

	// set options
	setOptions(newOptions) {
		const _ = this;
		// check to see if newOptions is different from _.options
		if (!areOptionsEqual(_.options, newOptions)) {
			_.options = newOptions;
			// emit options changed event
			_.emit('optionsChanged');
		}
	},

	setSelectedIndex(newIndex) {
		const _ = this;
		const oldIndex = _.selectedIndex;

		// exit if they're the same
		if (oldIndex === newIndex) return;

		// keep value within range
		if (newIndex < 0) {
			newIndex = 0;
		} else if (newIndex >= _.slides.length) {
			newIndex = _.slides.length - 1;
		}

		// update selected index value
		_.selectedIndex = newIndex;

		// tell everyone selected index changed
		_.emit('selectedIndexChanged', { oldIndex, newIndex });

		// should we go to this slide?
		if (_.options.goToSelectedSlide) {
			_.goToSlide(newIndex);
		}
	},

	// get methods
	getIndex() {
		return this.dislpayIndex;
	},

	getPage() {
		return this.page;
	},

	getSelectedIndex() {
		return this.selectedIndex;
	},
	getSelectedSlide() {
		return this.slides[this.selectedIndex];
	},

	getSlideAtIndex(index) {
		return this.slides[index];
	},

	// get option functions
	getUserOptions() {
		return this.optionsManager.getUserOptions();
	},

	getOptions() {
		return this.options;
	},

	getCurrentBreakpointOptions() {
		return this.currentBreakpoint.options;
	},

	/**
	 * Set new options for the carousel.
	 * @param {Object} newOptions - The new options to set.
	 */
	updateOptions(newOptions) {
		this.optionsManager.updateOptions(newOptions);
	},

	// event hooks
	on(event, listener) {
		this.events.on(event, listener);
	},

	off(event, listener) {
		this.events.off(event, listener);
	},

	emit(event, ...args) {
		this.events.emit(event, ...args);
	},

	// add mode to mode manager
	addMode(newMode) {
		this.modeManager.addMode(newMode);
	},
};
