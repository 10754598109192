import { throttle, debounce } from '../utilities/toolkit.js';

/**
 * handles window and viewport events for the carousel
 */
export default class WindowEvents {
	/**
	 * creates a new window events instance
	 * @param {TarotCarousel} carousel - the carousel instance
	 */
	constructor(carousel) {
		const _ = this;
		_.carousel = carousel;
		_.viewport = carousel.viewport;
		_.viewportWidth = _.viewport.offsetWidth;
		_.windowWidth = window.innerWidth;

		// define all event handlers in one object
		_.handlers = {
			// handle generic window resize (for breakpoints)
			handleResize: (event) => {
				// emit window resize event from the carousel
				_.carousel.emit('windowResize', event);
			},

			// handle window focus event
			handleWindowFocus: () => {
				_.carousel.emit('windowHasFocus');
			},

			// handle window blur event
			handleWindowBlur: () => {
				_.carousel.emit('windowLostFocus');
			},

			// handle carousel focus event
			handleCarouselFocus: () => {
				_.carousel.emit('carouselHasFocus');
			},

			// handle carousel blur event
			handleCarouselBlur: () => {
				_.carousel.emit('carouselLostFocus');
			},

			throttledResize: throttle((event) => {
				_.handlers.handleResize(event);
			}, 40),

			debouncedResize: debounce((event) => {
				_.handlers.handleResize(event);
			}, 100),
		};

		// create a resize observer for the viewport element (in case its size changes independently)
		_.viewportObserver = new ResizeObserver((event) => {
			const newWidth = _.viewport.offsetWidth;
			// this is always fired right away after attaching to the DOM,
			// so ignore the first call
			if (_.viewportWidth == newWidth) {
				return;
			}
			// save updated width
			_.viewportWidth = newWidth;
			// trigger handlers
			_.handlers.throttledResize(event);
			_.handlers.debouncedResize(event);
		});

		_.init();
	}

	/**
	 * initializes focus events, window resize events, and viewport observer
	 */
	init() {
		const _ = this;

		// window resize events - call both throttled and debounced handlers
		window.addEventListener('resize', (event) => {
			const newWindowWidth = window.innerWidth;

			// exit if no change
			if (_.windowWidth == newWindowWidth) {
				return;
			}

			// save new value
			_.windowWidth = newWindowWidth;

			// trigger handlers
			_.handlers.throttledResize(event);
			_.handlers.debouncedResize(event);
		});

		// focus and blur events for window and carousel
		window.addEventListener('focus', _.handlers.handleWindowFocus);
		window.addEventListener('blur', _.handlers.handleWindowBlur);
		_.carousel.addEventListener('focus', _.handlers.handleCarouselFocus, true);
		_.carousel.addEventListener('blur', _.handlers.handleCarouselBlur, true);

		// start observing the viewport for size changes
		if (_.viewport) {
			_.viewportObserver.observe(_.viewport);
		}
	}

	/**
	 * cleans up events and observers
	 */
	destroy() {
		const _ = this;
		// remove window resize events
		window.removeEventListener('resize', _.handlers.throttledResize);
		window.removeEventListener('resize', _.handlers.debouncedResize);

		// remove focus/blur events for window
		window.removeEventListener('focus', _.handlers.handleWindowFocus);
		window.removeEventListener('blur', _.handlers.handleWindowBlur);
		// remove focus/blur events for carousel
		_.carousel.removeEventListener('focus', _.handlers.handleCarouselFocus, true);
		_.carousel.removeEventListener('blur', _.handlers.handleCarouselBlur, true);

		// disconnect the viewport observer
		if (_.viewportObserver) {
			_.viewportObserver.disconnect();
		}
	}
}
