export default class Pagination {
	/**
	 * @constructor
	 * @param {Object} carousel - the carousel instance that the pagination will control.
	 */
	constructor(carousel) {
		const _ = this;
		_.carousel = carousel;
		_.navOptions = {};
		_.paginationContainer = null;

		// define all event handlers in one object
		_.handlers = {
			// handler for click events on pagination container
			paginationClick: (e) => {
				// trigger user interacted event
				_.carousel.emit('userInteracted');
				const target = e.target.closest('[data-action="tarot-go-to-page"]');
				if (!target) return;
				const pageIndex = parseInt(target.getAttribute('data-page-index'), 10);
				_.carousel.goToPage(pageIndex);
			},
			// handler for keydown events on pagination container
			keyDown: (e) => {
				let velocity = 0;
				const keys = ['ArrowLeft', 'ArrowRight', 'Home', 'End'];
				if (!keys.includes(e.key)) return;
				const tabs = Array.from(_.paginationContainer.querySelectorAll('.tarot-dots-button'));
				const currentIndex = tabs.findIndex((tab) => tab.getAttribute('aria-selected') === 'true');
				let newIndex;
				switch (e.key) {
					case 'ArrowRight':
						newIndex = currentIndex + 1;
						if (newIndex >= tabs.length && !_.carousel.options.loop) {
							newIndex = currentIndex;
							velocity = -10;
						} else if (newIndex >= tabs.length && _.carousel.options.loop) {
							newIndex = 0;
						}
						break;
					case 'ArrowLeft':
						newIndex = currentIndex - 1;
						if (newIndex < 0 && !_.carousel.options.loop) {
							newIndex = currentIndex;
							velocity = 10;
						} else if (newIndex < 0 && _.carousel.options.loop) {
							newIndex = tabs.length - 1;
						}
						break;
					case 'Home':
						newIndex = 0;
						break;
					case 'End':
						newIndex = tabs.length - 1;
						break;
					default:
						return;
				}
				e.preventDefault();
				_.carousel.emit('userInteracted');
				tabs[newIndex].focus();
				_.carousel.goToPage(newIndex, velocity);
			},
			// handler for carousel's optionsChanged event
			optionsChanged: () => {
				_.reInit();
			},
			// handler for carousel's slidesChanged event
			slidesChanged: () => {
				_.reInit();
			},
			// handler for carousel's pageChanged event
			pageChanged: () => {
				_.updateSelectedDot();
			},
		};

		_.bindEvents();
		_.init();
	}

	/**
	 * initialize the pagination container and render dots
	 */
	init() {
		const _ = this;
		let wasSelfGenerated = false;
		_.navOptions = _.carousel.options.navigation;

		// if a pagination element is provided via selector, use it
		if (_.navOptions.paginationSelector) {
			const paginationElement = document.querySelector(_.navOptions.paginationSelector);
			if (paginationElement) {
				_.paginationContainer = paginationElement;
			}
		}

		// if no container provided, generate one
		if (!_.paginationContainer) {
			wasSelfGenerated = true;
			// create a <nav> element with aria-label
			const nav = document.createElement('nav');
			nav.setAttribute('aria-label', 'carousel navigation');
			// create the pagination container as a <div>
			_.paginationContainer = document.createElement('div');
			nav.appendChild(_.paginationContainer);
			// append the nav to the carousel element
			_.carousel.appendChild(nav);
		}

		// clear existing content and set up the container
		_.paginationContainer.innerHTML = '';
		_.paginationContainer.classList.add('tarot-dots-container');

		// create the <ul> element for dots with role="tablist"
		_.dotsList = document.createElement('ul');
		_.dotsList.classList.add('tarot-dots-list');
		_.dotsList.setAttribute('role', 'tablist');
		_.paginationContainer.appendChild(_.dotsList);

		// add event listeners for pagination container events
		_.paginationContainer.addEventListener('click', _.handlers.paginationClick, false);
		_.paginationContainer.addEventListener('keydown', _.handlers.keyDown, false);

		// build the dots
		_.render();
	}

	/**
	 * reinitialize the pagination on carousel changes
	 */
	reInit() {
		const _ = this;
		_.navOptions = _.carousel.options.navigation;
		this.render();
	}

	/**
	 * bind carousel events to pagination handlers
	 */
	bindEvents() {
		const _ = this;
		const carousel = _.carousel;
		carousel.on('optionsChanged', _.handlers.optionsChanged);
		carousel.on('slidesChanged', _.handlers.slidesChanged);
		carousel.on('pageChanged', _.handlers.pageChanged);
	}

	/**
	 * build the pagination buttons for the carousel
	 */
	render() {
		const _ = this;
		const page = _.carousel.page;
		const pageCount = _.carousel.pageCount;

		// update carousel page count
		// _.carousel.pageCount = pageCount;

		// clear existing dots
		_.dotsList.innerHTML = '';

		for (let i = 0; i < pageCount; ++i) {
			// create <li> element with role="presentation"
			const listItem = document.createElement('li');
			listItem.setAttribute('role', 'presentation');

			// create <button> element with necessary ARIA attributes
			const button = document.createElement('button');
			button.type = 'button';
			button.classList.add('tarot-dots-button');
			button.setAttribute('role', 'tab');
			button.setAttribute('data-page-index', i);
			button.setAttribute('data-action', 'tarot-go-to-page');
			button.setAttribute('aria-selected', page === i ? 'true' : 'false');
			button.tabIndex = page === i ? 0 : -1;
			button.setAttribute('aria-label', `Page ${i + 1}`);
			button.id = `carousel-tab${i + 1}`;

			// create visually hidden <span>
			const span = document.createElement('span');
			span.className = 'visually-hidden';
			span.textContent = `Slide ${i + 1}`;

			button.appendChild(span);
			listItem.appendChild(button);
			_.dotsList.appendChild(listItem);
		}

		// update selected dot based on carousel state
		_.updateSelectedDot();

		// show or hide pagination based on options
		if (_.navOptions.showPagination) {
			_.show();
		} else {
			_.hide();
		}
	}

	/**
	 * update the selected pagination dot based on the carousel's current state
	 */
	updateSelectedDot() {
		const _ = this;
		const dots = _.paginationContainer.querySelectorAll('.tarot-dots-button');
		const currentPage = `${_.carousel.page}`;
		dots.forEach((dot) => {
			if (dot.dataset.pageIndex === currentPage) {
				dot.setAttribute('aria-selected', 'true');
				dot.tabIndex = 0;
			} else {
				dot.setAttribute('aria-selected', 'false');
				dot.tabIndex = -1;
			}
		});
	}

	/**
	 * show the pagination container
	 */
	show() {
		if (this.paginationContainer.parentElement) {
			this.paginationContainer.parentElement.style.display = '';
		} else {
			this.paginationContainer.style.display = '';
		}
	}

	/**
	 * hide the pagination container
	 */
	hide() {
		if (this.paginationContainer.parentElement) {
			this.paginationContainer.parentElement.style.display = 'none';
		} else {
			this.paginationContainer.style.display = 'none';
		}
	}

	/**
	 * destroy the pagination, unbinding all events and removing elements if necessary
	 */
	destroy() {
		const _ = this;
		// remove event listeners from the pagination container
		if (_.paginationContainer) {
			_.paginationContainer.removeEventListener('click', _.handlers.paginationClick, false);
			_.paginationContainer.removeEventListener('keydown', _.handlers.keyDown, false);
		}

		// unbind carousel events
		_.carousel.off('optionsChanged', _.handlers.optionsChanged);
		_.carousel.off('slidesChanged', _.handlers.slidesChanged);
		_.carousel.off('pageChanged', _.handlers.pageChanged);

		// if the pagination container was self-generated, remove its parent <nav>
		if (
			_.paginationContainer &&
			_.paginationContainer.parentNode &&
			_.paginationContainer.parentNode.parentNode === _.carousel
		) {
			_.carousel.removeChild(_.paginationContainer.parentNode);
		}

		_.paginationContainer = null;
	}
}
