import CarouselMode from '../modes/carousel.js';
// import CarouselCenterMode from '../modes/carousel-center.js';
import FadeMode from '../modes/fade.js';
import Ripple from '../modes/ripple.js';

/**
 * manages the different display modes for the carousel
 */
export default class ModeManager {
	// a list of the registered modes available
	static modes = {
		carousel: CarouselMode,
		fade: FadeMode,
		ripple: Ripple,
	};

	/**
	 * add a mode to the registered list
	 * @param {string} name - name of the mode
	 * @param {class} modeClass - the mode class to register
	 */
	static addMode(name, modeClass) {
		const _ = ModeManager;
		if (_.modes[name]) {
			console.warn(`Mode '${name}' already exists. It will be overwritten.`);
		}
		_.modes[name] = modeClass;
	}

	/**
	 * @constructor
	 * @param {Object} carousel - the carousel instance to manage modes for
	 */
	constructor(carousel) {
		const _ = this;
		_.carousel = carousel;

		// define all event handlers in one object
		_.handlers = {
			// handler for carousel's optionsChanged event
			optionsChanged: () => {
				// only load new module if the name has changed
				if (_.carousel.options.mode !== _.carousel.mode?.constructor.name) {
					_.loadMode(_.carousel.options.mode);
				}
			},
		};

		_.bindEvents();
	}

	/**
	 * initialize the mode manager
	 */
	init() {
		// implementation as needed
	}

	/**
	 * reinitialize on carousel option changes
	 */
	reInit() {
		this.loadCurrentMode();
	}

	/**
	 * bind carousel events to handlers
	 */
	bindEvents() {
		const _ = this;
		_.carousel.on('optionsChanged', _.handlers.optionsChanged);
	}

	/**
	 * load the current mode based on carousel options
	 */
	loadCurrentMode() {
		this.loadMode(this.carousel.options.mode);
	}

	/**
	 * load a specific mode by name
	 * @param {string} modeName - name of the mode to load
	 */
	loadMode(modeName) {
		const _ = this;
		if (!modeName) {
			console.error('Error: no mode detected or could not load mode ', modeName);
			return;
		}

		const NewModeClass = ModeManager.modes[modeName];
		if (!NewModeClass) {
			console.error(`Error: could not find mode with name ${modeName}`);
			return;
		}

		// clear out old mode
		if (_.carousel.mode) {
			_.carousel.mode.destroy();
			delete _.carousel.mode;
			_.carousel.mode = null;
		}

		// create new mode instance
		_.carousel.mode = new NewModeClass(_.carousel);

		// set name in DOM
		_.carousel.setAttribute('data-mode', modeName);

		// tell mode to do initial render
		_.carousel._requestModeRender({ trackPosition: _.carousel.trackPosition });
	}

	/**
	 * destroy the mode manager, unbinding all events
	 */
	destroy() {
		const _ = this;

		// unbind from carousel events
		_.carousel.off('optionsChanged', _.handlers.optionsChanged);

		// destroy current mode if exists
		if (_.carousel.mode) {
			_.carousel.mode.destroy();
			delete _.carousel.mode;
			_.carousel.mode = null;
		}
	}
}
