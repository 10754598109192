import { debounce } from '../utilities/toolkit.js';

/**
 * Direction constants for tracking touch/drag movements
 * @enum {number}
 */
const DIRECTION = {
	UNKNOWN: 0,
	VERTICAL: -1,
	HORIZONTAL: 1,
};

/**
 * Handles all drag, touch, and scroll interactions with the carousel
 * @class DragHandler
 */
export default class DragHandler {
	/**
	 * Creates a new drag handler for the carousel
	 * @param {Object} carousel - The carousel instance this handler will control
	 */
	constructor(carousel) {
		const _ = this;
		/** @type {Object} - Reference to parent carousel */
		_.carousel = carousel;

		/** @type {HTMLElement} - Element to bind touch/drag events to */
		_.track = carousel.track;

		/** @type {number} - Minimum pixels of movement required before a drag is recognized */
		_.dragThreshold = 3;

		// Apply CSS to prevent scroll inertia
		// _.track.style.overscrollBehaviorX = 'none';
		// _.track.style.scrollBehavior = 'auto';
		// Ensure touch-action is set to none to prevent any native scrolling
		// _.track.style.touchAction = 'none';

		/**
		 * @type {Object} - Object containing all drag state information
		 */
		_.drag = {
			/** @type {boolean} - Whether a drag is currently active */
			isDragging: false,
			/** @type {number} - Starting position of the drag */
			start: 0,
			/** @type {number} - Current position during drag */
			current: 0,
			/** @type {number} - Previous position during drag */
			prev: 0,
			/** @type {number} - Distance moved since drag started */
			delta: 0,
			/** @type {number} - Speed of movement */
			velocity: 0,
			/** @type {boolean} - Whether drag exceeds minimum threshold */
			dragThresholdMet: false,
			/** @type {number} - Accumulated delta from wheel events */
			scrollWheelDelta: 0,
			/** @type {boolean} - Whether wheel scrolling is active */
			scrollWheelActive: false,
		};

		/** @type {Function} - Debounced function to handle the end of scroll wheel events */
		_.debouncedScrollEnd = debounce((e) => {
			_.drag.scrollWheelDelta = 0;
			_.handleScrollEnd(e);
		}, 80);

		_.bindUI();
	}

	/**
	 * Initialize drag events on the carousel track element.
	 * Binds all necessary event listeners for different interaction methods.
	 */
	bindUI() {
		const _ = this;
		const track = _.track;

		track.addEventListener('click', (e) => _.handleClick(e));
		track.addEventListener('pointerdown', (e) => _.handleDragStart(e), {
			passive: false,
		});
		track.addEventListener('pointermove', (e) => _.handleDragMove(e), {
			passive: false,
		});
		track.addEventListener('pointerup', (e) => _.handleDragEnd(e), {
			passive: false,
		});
		track.addEventListener('pointerleave', (e) => _.handleDragEnd(e), {
			passive: false,
		});
		track.addEventListener('pointercancel', (e) => _.handleDragEnd(e), {
			passive: false,
		});
		track.addEventListener('touchstart', (e) => _.handleTouchStart(e), {
			passive: false,
		});
		track.addEventListener('touchmove', (e) => _.handleTouchMove(e), {
			passive: false,
		});

		// Prevent double taps
		track.addEventListener('dblclick', (e) => {
			e.preventDefault();
			e.stopPropagation();
			return false;
		});

		// Add sideways scrolling with two fingers
		// track.addEventListener('wheel', (e) => _.handleSidewaysScroll(e), {
		// 	passive: false,
		// });
	}

	/**
	 * Handle sideways scroll events on the track.
	 * This allows for horizontal scrolling using a trackpad.
	 * @param {WheelEvent} e - The wheel event.
	 */
	handleSidewaysScroll(e) {
		const _ = this;
		// check if there is significant horizontal movement
		if (Math.abs(e.deltaX) > Math.abs(e.deltaY)) {
			e.preventDefault(); // prevent page scrolling

			// emit user interaction
			_.carousel.emit('userInteracted');

			const drag = _.drag;

			if (drag.delta === 0) {
				_.carousel._dragStart();
			}

			// use the deltaX to move the carousel accordingly
			drag.delta += e.deltaX * -1;

			// let plugin know the movement
			_.carousel._dragMove(e, drag);

			_.debouncedScrollEnd(e);
		}
	}

	/**
	 * Handles the end of a scroll wheel event
	 * Notifies the carousel mode when scrolling has stopped
	 *
	 * @param {WheelEvent} e - The wheel event
	 */
	handleScrollEnd(e) {
		const _ = this;
		const drag = _.drag;

		// Tell plugin the drag has ended
		_.carousel._dragEnd(e, drag);

		// Reset the delta
		drag.delta = 0;
	}

	/**
	 * Handle click events on the track.
	 * Prevents click if drag threshold was met.
	 * @param {Event} e - The click event.
	 */
	handleClick(e) {
		const _ = this;

		_.carousel.emit('userInteracted');

		// user was dragging - not a click
		if (_.drag.dragThresholdMet) {
			e.preventDefault();
			return;
		}

		// emit click event
		_.carousel.slideClicked(e);
	}

	/**
	 * Handle touch start events on the track.
	 * Disables dragging if more than one touch is detected.
	 * @param {TouchEvent} e - The touch start event.
	 */
	handleTouchStart(e) {
		const _ = this;
		_.carousel.emit('userInteracted');

		// tell plugin user clicked on slide
		if (e.touches.length > 1) {
			_.drag.isDragging = false;
		}
	}

	/**
	 * Handle touch move events on the track.
	 * Determines the direction of touch movement and
	 * prevents vertical scrolling if horizontal movement is detected.
	 * @param {TouchEvent} e - The touch move event.
	 */
	handleTouchMove(e) {
		const _ = this;
		const drag = _.drag;

		_.carousel.emit('userInteracted');

		// exit if a touchstart drag wasn't initiated
		if (!drag.isDragging) return;

		// exit if we have already determined we are
		// scrolling horizontally and not vertically
		if (drag.touchDirection === DIRECTION.HORIZONTAL) {
			// prevent scrolling on page
			e.preventDefault();
			return;
		}

		// calculate delta X and Y values from touchmove events
		const deltaX = Math.abs(drag.startX - e.touches[0].screenX);
		const deltaY = Math.abs(drag.startY - e.touches[0].screenY);

		// confirm we are scrolling horizontially
		if (deltaX * 1.15 > deltaY) {
			// prevent scrolling on page
			e.preventDefault();
			// save touch direction as horizontal
			drag.touchDirection = DIRECTION.HORIZONTAL;
			return;
		}

		// cancel drag because drag direction is vertical
		drag.isDragging = false;
		drag.touchDirection = DIRECTION.VERTICAL;
		drag.delta = 0;

		// tell plugin we are ending drag because direction is vertical
		_.carousel._dragEnd(e, drag);
	}

	/**
	 * Handle drag start events on the track.
	 * Initializes drag data and notifies the plugin.
	 * @param {PointerEvent} e - The pointer down event.
	 */
	handleDragStart(e) {
		// we need this to properly capture drag events
		e.preventDefault();

		const _ = this;
		const drag = _.drag;

		_.carousel.emit('userInteracted');

		drag.hasTouch = e.pointerType === 'touch';
		drag.touchDirection = DIRECTION.UNKNOWN;

		// save initial drag start values
		drag.isDragging = true;
		drag.dragThresholdMet = false;
		drag.startX = e.screenX;
		drag.startY = e.screenY;
		drag.velocity = 0;
		drag.delta = 0;

		// let plugin know drag start has begin
		_.carousel._dragStart(e, drag);
	}

	/**
	 * Handle drag move events on the track.
	 * Updates drag data and notifies the plugin if the drag threshold is met.
	 * @param {PointerEvent} e - The pointer move event.
	 */
	handleDragMove(e) {
		const _ = this;
		const drag = _.drag;

		_.carousel.emit('userInteracted');

		// exit if we are not actively dragging
		if (!drag.isDragging) return;

		// check to see what direction we're going
		const deltaX = Math.abs(drag.startX - e.screenX);
		const deltaY = Math.abs(drag.startY - e.screenY);

		// direction still unknown but looks to be going horizontal
		if (drag.touchDirection === DIRECTION.UNKNOWN && deltaX * 1.15 > deltaY) {
			// we are going horizontal!!
			drag.touchDirection = DIRECTION.HORIZONTAL;
		}

		// exit if has touch and direction is vertical or undefined
		if (drag.hasTouch && drag.touchDirection != DIRECTION.HORIZONTAL) {
			// don't scroll and don't prevent default
			return;
		}

		// we are dragging horizontally so we must prevent scrolling on page
		e.preventDefault();

		// update drag event values with current event data
		drag.prev = drag.current;
		drag.current = e.screenX;
		drag.delta = drag.current - drag.startX;
		drag.velocity = drag.current - drag.prev;

		// check to see if drag threshold has been met
		if (!drag.dragThresholdMet && Math.abs(drag.delta) > _.dragThreshold) {
			drag.dragThresholdMet = true;
		}

		// exit and don't move if threshold hasn't been met yet
		if (!drag.dragThresholdMet) return;

		// tell plugin a drag move has occured
		_.carousel._dragMove(e, drag);
		return false;
	}

	/**
	 * Handle drag end events on the track.
	 * Finalizes drag data and notifies the plugin.
	 * @param {PointerEvent} e - The pointer up or pointer leave event.
	 */
	handleDragEnd(e) {
		const _ = this;
		const drag = _.drag;

		_.carousel.emit('userInteracted');

		// exit if we are not actively dragging
		if (!drag.isDragging) return;

		// prevent scrolling on page
		e.preventDefault();

		// we are now done dragging
		drag.isDragging = false;

		// tell plugin the drag has ended
		_.carousel._dragEnd(e, drag);

		// clear drag delta
		drag.delta = 0;
	}

	/**
	 * Clean up event listeners and cancel any pending operations
	 * Should be called when the carousel is destroyed to prevent memory leaks
	 */
	destroy() {
		if (this.debouncedScrollEnd) {
			this.debouncedScrollEnd.cancel();
		}
	}
}
