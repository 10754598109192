class TarotViewport extends HTMLElement {
	constructor() {
		super();
	}
}

class TarotSlides extends HTMLElement {
	constructor() {
		super();
	}
}

class TarotSlide extends HTMLElement {
	// This is the index the slide will render at
	// The LoopManager uses this to loop slides on the track
	// The renderIndex might be different from the slide index
	#renderIndex;

	constructor() {
		super();
	}

	setRenderIndex(renderIndex) {
		this.#renderIndex = renderIndex;
	}

	getRenderIndex() {
		return this.#renderIndex;
	}
}

class TarotContent extends HTMLElement {
	constructor() {
		super();
	}
}

class TarotSlideIcon extends HTMLElement {
	constructor() {
		super();
	}
}

customElements.define('tarot-viewport', TarotViewport);
customElements.define('tarot-slides', TarotSlides);
customElements.define('tarot-slide', TarotSlide);
customElements.define('tarot-content', TarotContent);
customElements.define('tarot-slide-icon', TarotSlideIcon);
