export default class Buttons {
	/**
	 * @constructor
	 * @param {Object} carousel - the carousel instance that the buttons will control
	 */
	constructor(carousel) {
		const _ = this;
		_.carousel = carousel;
		_.navOptions = {};
		_.prev = null;
		_.next = null;

		// define all event handlers in one object
		_.handlers = {
			// handler for prev button click
			prevClick: (e) => {
				// trigger user interacted event
				_.carousel.emit('userInteracted');
				_.carousel.prev();
			},

			// handler for next button click
			nextClick: (e) => {
				// trigger user interacted event
				_.carousel.emit('userInteracted');
				_.carousel.next();
			},

			// handler for button focus
			buttonFocus: (e) => {
				if (_.carousel.autoplay && _.carousel.plugin.pauseAutoPlay) {
					_.carousel.plugin.pauseAutoPlay();
				}
			},

			// handler for button blur
			buttonBlur: (e) => {
				if (_.carousel.plugin && _.carousel.plugin.resumeAutoPlay) {
					_.carousel.startAutoplay();
					_.carousel.plugin.resumeAutoPlay();
				}
			},

			// handler for carousel's optionsChanged event
			optionsChanged: () => {
				_.reInit();
			},

			// handler for carousel's pageChanged event
			pageChanged: () => {
				_.checkDisabledState();
			},

			windowResize: () => {
				_.updateSmartButtons();
			},
		};

		_.init();
	}

	/**
	 * initialize the navigation buttons
	 */
	init() {
		const _ = this;
		_.navOptions = _.carousel.options.navigation;

		// pull buttons from DOM
		_.queryButtons();
		// build them if they don't exist
		// must be in this order for in the DOM
		if (!_.next) _.buildNextButton();
		if (!_.prev) _.buildPrevButton();

		// bind to UI
		_.checkButtonOptions();
		_.bindUI();
		_.bindEvents();
		_.checkDisabledState();

		setTimeout(() => {
			_.updateSmartButtons();
		}, 8);

		setTimeout(() => {
			_.updateSmartButtons();
		}, 30);
	}

	/**
	 * reinitialize on carousel option changes
	 */
	reInit() {
		const _ = this;
		_.navOptions = _.carousel.options.navigation;
		_.checkButtonOptions();
		_.checkDisabledState();
		_.updateSmartButtons();
	}

	/**
	 * bind ui events to button handlers
	 */
	bindUI() {
		const _ = this;
		// make sure we don't double bind
		_.unbindUI();
		if (_.prev) {
			_.prev.addEventListener('click', _.handlers.prevClick, true);
			_.prev.addEventListener('focus', _.handlers.buttonFocus);
			_.prev.addEventListener('blur', _.handlers.buttonBlur);
		}
		if (_.next) {
			_.next.addEventListener('click', _.handlers.nextClick, true);
			_.next.addEventListener('focus', _.handlers.buttonFocus);
			_.next.addEventListener('blur', _.handlers.buttonBlur);
		}

		// bind to first image load
		if (_.carousel.slides.length > 0) {
			let firstImage = _.carousel.slides[0].querySelector('img') || false;
			if (firstImage && firstImage.complete) {
				_.updateSmartButtons(firstImage);
			} else if (firstImage) {
				firstImage.onload = function () {
					_.updateSmartButtons(firstImage);
				};
			}
		}
	}

	/**
	 * unbind ui events from button handlers
	 */
	unbindUI() {
		const _ = this;
		if (_.prev) {
			_.prev.removeEventListener('click', _.handlers.prevClick, true);
			_.prev.removeEventListener('focus', _.handlers.buttonFocus);
			_.prev.removeEventListener('blur', _.handlers.buttonBlur);
		}
		if (_.next) {
			_.next.removeEventListener('click', _.handlers.nextClick, true);
			_.next.removeEventListener('focus', _.handlers.buttonFocus);
			_.next.removeEventListener('blur', _.handlers.buttonBlur);
		}
	}

	/**
	 * bind carousel events to handlers
	 */
	bindEvents() {
		const _ = this;
		_.carousel.on('optionsChanged', _.handlers.optionsChanged);
		_.carousel.on('pageChanged', _.handlers.pageChanged);
		_.carousel.on('windowResize', _.handlers.windowResize);
	}

	/**
	 * check button options and show/hide buttons accordingly
	 */
	checkButtonOptions() {
		const _ = this;
		_.navOptions.showButtons ? _.showButtons() : _.hideButtons();
	}

	/**
	 * query buttons from the dom
	 */
	queryButtons() {
		const _ = this;
		// query all potential prev/next buttons within the current carousel
		const allPrevButtons = _.carousel.querySelectorAll('[data-action="tarot-prev"]');
		const allNextButtons = _.carousel.querySelectorAll('[data-action="tarot-next"]');

		// filter them so that their closest ancestor carousel is the current one.
		_.prev =
			Array.from(allPrevButtons).find((btn) => btn.closest('tarot-carousel') === _.carousel) ||
			null;
		_.next =
			Array.from(allNextButtons).find((btn) => btn.closest('tarot-carousel') === _.carousel) ||
			null;
	}

	/**
	 * build the prev button if it doesn't exist
	 */
	buildPrevButton() {
		const _ = this;
		const prevButtonHTML = `
      <button class="tarot-button" data-action="tarot-prev" aria-label="previous slide">
        <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
          <title>angle left</title>
          <path d="m21 7-9 9 9 9" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
        </svg>
      </button>
    `;
		// add button to DOM
		_.carousel.insertAdjacentHTML('afterbegin', prevButtonHTML);
		// query DOM for button
		_.prev = _.carousel.querySelector('[data-action="tarot-prev"]');
		_.carousel.prevButton = _.prev;
	}

	/**
	 * build the next button if it doesn't exist
	 */
	buildNextButton() {
		const _ = this;
		const nextButtonHTML = `
      <button class="tarot-button" data-action="tarot-next" aria-label="next slide">
        <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
          <title>angle right</title>
          <path d="m11 25 9-9-9-9" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
        </svg>
      </button>
    `;
		_.carousel.insertAdjacentHTML('afterbegin', nextButtonHTML);
		_.next = _.carousel.querySelector('[data-action="tarot-next"]');
		_.carousel.nextButton = _.next;
	}

	/**
	 * show the navigation buttons
	 */
	showButtons() {
		const _ = this;
		const prevButton = _.prev;
		const nextButton = _.next;

		// Even if showButtons: true they can set each button like
		// showPrevButton: false to only show one
		if (prevButton && _.navOptions.showPrevButton) {
			prevButton.style.display = '';
			prevButton.setAttribute('aria-label', 'previous');
			prevButton.classList.add('tarot-button', 'tarot-prev');
		} else {
			_.hidePrevButton();
		}

		if (nextButton && _.navOptions.showNextButton) {
			nextButton.style.display = '';
			nextButton.setAttribute('aria-label', 'next');
			nextButton.classList.add('tarot-button', 'tarot-next');
		} else {
			_.hideNextButton();
		}
	}

	/**
	 * hide both buttons
	 */
	hideButtons() {
		this.hidePrevButton();
		this.hideNextButton();
	}

	/**
	 * hide the prev button
	 */
	hidePrevButton() {
		const _ = this;
		if (_.prev) _.prev.style.display = 'none';
	}

	/**
	 * hide the next button
	 */
	hideNextButton() {
		const _ = this;
		if (_.next) _.next.style.display = 'none';
	}

	// smart buttons
	updateSmartButtons() {
		const _ = this;
		const carousel = _.carousel;
		const smartButtons = carousel.options.navigation.smartButtons || false;
		const prevButton = _.prev;
		const nextButton = _.next;
		const minDistance = 40;
		const viewport = carousel.viewport;
		const viewportWidth = viewport.offsetWidth;
		const slides = carousel.slides;

		// bail if we don't have a viewport or any slides
		if (!viewport) return;
		if (!carousel.slides || slides.length === 0) return;

		// remove classes if not using smart buttons
		if (!smartButtons) {
			if (prevButton) {
				prevButton.classList.remove('tarot-smart-position');
			}
			if (nextButton) {
				nextButton.classList.remove('tarot-smart-position');
			}
			return;
		}

		// fetch info from the carousel

		const firstSlide = slides[0];

		// read computed styles in case we need padding
		const viewportStyles = window.getComputedStyle(viewport);
		const viewportTopPadding = parseInt(viewportStyles.paddingTop) || 0;

		// figure out a rough button vertical position
		let buttonTopPos = viewport.offsetHeight;

		// if the first slide has an <img>, maybe use its height
		const firstImage = firstSlide.querySelector('img');
		if (firstImage && firstImage.offsetHeight < buttonTopPos) {
			// add top padding if relevant
			buttonTopPos = firstImage.height + viewportTopPadding * 2;
		}

		// half it so it’s approximately centered
		buttonTopPos = buttonTopPos / 2;

		// set prev button
		if (prevButton) {
			prevButton.classList.add('tarot-smart-position');
			prevButton.style.top = `${buttonTopPos}px`;

			// figure out potential positions
			let leftPos = minDistance;
			let leftPosWithGap = carousel.paddingLeftWidth - carousel.gapWidth / 2;
			let leftPosOnlyPadding = carousel.paddingLeftWidth;

			// if the carousel is edge-to-edge
			if (window.innerWidth === viewportWidth) {
				if (leftPosWithGap < minDistance) {
					if (leftPosOnlyPadding >= minDistance) {
						leftPos = leftPosOnlyPadding;
					} else if (carousel.gapWidth > 0) {
						leftPos += carousel.gapWidth;
					}
				} else if (leftPosWithGap >= minDistance) {
					leftPos = leftPosWithGap;
				}
			} else {
				// if it's not edge-to-edge
				leftPos = leftPosOnlyPadding;
			}

			prevButton.style.left = `${leftPos}px`;
		}

		// set next button
		if (nextButton) {
			nextButton.classList.add('tarot-smart-position');
			nextButton.style.top = `${buttonTopPos}px`;

			// figure out potential positions for the right side
			let rightPos = minDistance;
			let rightPosWithGap = carousel.paddingRightWidth - carousel.gapWidth / 2;
			let rightPosOnlyPadding = carousel.paddingRightWidth;

			if (window.innerWidth === viewportWidth) {
				if (rightPosWithGap < minDistance) {
					if (rightPosOnlyPadding >= minDistance) {
						rightPos = rightPosOnlyPadding;
					} else if (carousel.gapWidth > 0) {
						rightPos += carousel.gapWidth;
					}
				} else if (rightPosWithGap >= minDistance) {
					rightPos = rightPosWithGap;
				}
			} else {
				rightPos = rightPosOnlyPadding;
			}

			nextButton.style.right = `${rightPos}px`;
		}
	}

	/**
	 * check and update disabled state of buttons
	 */
	checkDisabledState() {
		const _ = this;
		const page = _.carousel.page;
		// if we aren't looping
		if (!_.carousel.options.loop) {
			// check prev button
			if (_.prev) {
				if (page == 0) {
					_.prev.disabled = true;
				} else {
					_.prev.disabled = false;
				}
			}

			// check next button
			if (_.next) {
				if (page == _.carousel.pageCount - 1) {
					_.next.disabled = true;
				} else {
					_.next.disabled = false;
				}
			}
		}
	}

	/**
	 * destroy the buttons, unbinding all events
	 */
	destroy() {
		const _ = this;
		_.unbindUI();

		// unbind carousel events
		_.carousel.off('optionsChanged', _.handlers.optionsChanged);
		_.carousel.off('pageChanged', _.handlers.pageChanged);

		_.prev = null;
		_.next = null;
	}
}
