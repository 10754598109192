// import TrackAnimator from './utilities/track-animator.js';
import { convertValueToNumber } from '../utilities/toolkit';
// import LoopManager from './utilities/loop-manager.js';
import RippleWindow from './utilities/ripple-window.js';
import RippleTransforms from './utilities/ripple-transforms.js';

export default class RippleMode {
	static name = 'ripple';

	constructor(carousel) {
		const _ = this;
		_.carousel = carousel;
		_.track = carousel.track;
		_.viewport = carousel.viewport;

		// widths
		_.slideWidth = 0;
		_.slideAndGapWidth = 0;

		_.rippleWindow = new RippleWindow(carousel, _);
		_.rippleTransforms = new RippleTransforms(carousel, _);

		_.sortedSlides = null;

		_.previousPosition = 0;

		_.init();
	}

	init() {
		// init ripple effects
		this.rippleWindow.init();
		this.rippleTransforms.init();
		this.copyValuesFromCarousel();
	}

	reInit() {
		this.rippleWindow.reInit();
		this.rippleTransforms.reInit();
		this.copyValuesFromCarousel();
	}

	copyValuesFromCarousel() {
		const _ = this;
		const carousel = _.carousel;

		_.slideWidth = carousel.slideWidth;
		_.slideAndGapWidth = carousel.slideWidth + carousel.gapWidth;

		// we need to set the max value that the window fluctuates between
		_.rippleWindow.setMaxValue(_.slideWidth - carousel.slideMinWidth);
	}

	renderWithPreviousPosition() {
		this.render;
	}

	/**
	 * position all slides on the track
	 * runs with every animation frame when track is moving
	 * @param {Object} params - render parameters
	 * @param {number} params.trackPosition - current position of the track
	 * @param {number} params.trackDelta - change in position since last frame
	 * @param {number} params.velocity - current velocity of movement
	 * @param {number} params.progress - progress value from 0 to 1
	 * @param {string} params.animationType - type of animation ("jump", "animate", "settle")
	 * @returns {void}
	 */
	render({
		slides,
		trackPosition = 0,
		trackDelta = 0,
		velocity = 0,
		progress = 1,
		animationType = 'jump',
	}) {
		const _ = this;
		const slidesArray = Array.from(slides);

		// console.log('trackPosition', trackPosition);

		// add amount to sliding window but only with 'animate' or 'drag' movements
		if ((animationType === 'animate' || animationType === 'drag') && progress <= 1) {
			// console.log('adding to window', trackDelta);
			_.rippleWindow.addAmount(trackDelta, trackPosition);
		}

		// console.log('progress', progress);

		// TODO: figure out the settling part of this
		// if animating and > 0.9 -> trigger settle
		// if animationType == settling -> trigger settle
		if (animationType === 'settle' || (animationType === 'animate' && progress > 0.75)) {
			_.rippleWindow.snapToValue();
		}

		// everything must be sorted like an autistic kid with a pile of stuffed animals
		slidesArray.sort((a, b) => a.getRenderIndex() - b.getRenderIndex());

		const transformPercent = _.rippleWindow.getPercent();

		let totalRemovedRight = 0;
		let slide, slideIndex;
		// first pass: figure out each slide's display width and position
		for (let i = 0, n = slidesArray.length; i < n; ++i) {
			slide = slidesArray[i];
			slideIndex = slide.getRenderIndex();

			slide.trackXPos = _.slideAndGapWidth * slideIndex;
			slide.displayXPos = slide.trackXPos;
			slide.width = _.slideWidth;

			slide.displayWidthRight = 0;
			slide.displayWidthLeft = 0;

			// calculate center pos for transforms
			slide.centerPos = slide.trackXPos + _.slideWidth / 2;

			// apply ripple transforms ~~~~~~~~~~~~~~~~~~~~~~~~~~~
			// there's a whole bunch of magic happening here 🪄
			//  ╰( ͡◕ ͜ʖ ͡◕ )つ──~~⚝⚝⚝★★★🌟✨✨✨⚡⚡⚡💥💥💥🔥🔥🔥⚝:・ﾟ*
			_.rippleTransforms.applyTransforms(slide, trackPosition, transformPercent);

			// final display width
			slide.displayWidth = _.slideWidth + slide.displayWidthRight + slide.displayWidthLeft;
			// shift slide right based on position
			slide.displayXPos += totalRemovedRight;
			slide.style.transition = 'none';
			slide.style.width = `${slide.displayWidth}px`;

			// add to cumulative shift
			totalRemovedRight += slide.displayWidthRight;
		}

		// second pass: handle left offset
		let totalRemovedLeft = 0;
		for (let k = slidesArray.length - 1; k > -1; --k) {
			slide = slidesArray[k];
			// add width removed to total removed left
			totalRemovedLeft += slide.displayWidthLeft;
			// subtrack this from the display value
			slide.displayXPos -= totalRemovedLeft;
			// update pos on track
			slide.style.transform = `translateX(${slide.displayXPos}px)`;
		}
	}

	destroy() {
		const _ = this;

		// call destroy on subcomponents
		_.rippleWindow.destroy();
		_.rippleTransforms.destroy();

		const slides = _.carousel.slides;
		let slide;
		for (let i = 0, n = slides.length; i < n; ++i) {
			slide = slides[i];
			slide.removeAttribute('aria-hidden');
			slide.style.width = null;
			slide.style.transform = null;
		}

		// clear references for garbage collection
		_.carousel = null;
		_.track = null;
		_.viewport = null;
		_.rippleWindow = null;
		_.rippleTransforms = null;
		_.sortedSlides = null;
	}
}
