/**
 * @class eventManager - manages event bindings for the carousel component
 */
export default class EventManager {
	/**
	 * creates a new event manager instance
	 * @param {Object} carousel - the parent carousel instance that owns this manager
	 */
	constructor(carousel) {
		const _ = this;

		/** @type {Object} - reference to parent carousel */
		_.carousel = carousel;

		// define event handlers
		_.handlers = {
			optionsChanged: (data) => {
				// _.reInitMode();
				_.carousel._calculateWidths();
				_.reInitMode();

				// _.carousel._requestModeRender({ trackPosition: _.carousel.trackposition });
			},
			// pageChanged: ({ oldPage, newPage, velocity }) => {},
			// willTransition: (data) => {},
			// didTransition: (data) => {},
			// carouselHasFocus: (data) => {},
			// carouselLostFocus: (data) => {},
			// windowHasFocus: (data) => {},
			// windowLostFocus: (data) => {},
			windowResize: (event) => {
				_.carousel._calculateWidths();
				_.reInitMode();
				_.carousel.jumpToSlide(_.carousel.displayIndex);
				_.carousel.loopManager.reInit();
				// _.carousel._requestModeRender({ trackPosition: _.carousel.trackposition });
			},
			windowOrientationChanged: (event) => {
				_.carousel._calculateWidths();
				_.reInitMode();
				_.carousel.jumpToSlide(_.carousel.displayIndex);
				_.carousel.loopManager.reInit();

				// _.carousel._requestModeRender({ trackPosition: _.carousel.trackposition });
			},
			// trackPositionChanged: ({ oldPosition, newPosition, trackDelta, progress, animationType }) => {
			// console.log('EVENT MANAGER - track position changed', newPosition);
			// tell mode to reposition slides
			// },
			// slideClicked: (event) => {},
			// selectedIndexChanged: (data) => {},
			// displayIndexChanged: ({ oldIndex, newIndex, velocity, animate = true }) => {
			// if (!_.carousel.mode) return;
			// _.carousel.displayIndexChanged({ oldIndex, newIndex, velocity, animate });
			// },
			slidePositionsChanged: () => {
				// console.log('event manager - slidePositionsChanged');
				_.carousel._requestModeRender({});
			},
			// reInit: (data) => {},
		};

		// bind all events on creation
		_.bindEvents();
		_.init();
	}

	/**
	 * initialize the event manager
	 */
	init() {}

	/**
	 * reinitialize the event manager, e.g. after configuration changes
	 */
	reInit() {}

	reInitMode() {
		if (!this.carousel.mode) return;
		this.carousel.mode.reInit();
	}

	/**
	 * bind events from the carousel to corresponding handlers
	 */
	bindEvents() {
		const _ = this;
		// bind each event to its handler
		_.carousel.on('optionsChanged', _.handlers.optionsChanged);
		// _.carousel.on('userInteracted', _.handlers.userInteracted);
		// _.carousel.on('pageChanged', _.handlers.pageChanged);
		// _.carousel.on('willTransition', _.handlers.willTransition);
		// _.carousel.on('didTransition', _.handlers.didTransition);
		// _.carousel.on('carouselHasFocus', _.handlers.carouselHasFocus);
		// _.carousel.on('carouselLostFocus', _.handlers.carouselLostFocus);
		// _.carousel.on('windowHasFocus', _.handlers.windowHasFocus);
		// _.carousel.on('windowLostFocus', _.handlers.windowLostFocus);
		_.carousel.on('windowResize', _.handlers.windowResize);
		_.carousel.on('windowOrientationChanged', _.handlers.windowOrientationChanged);
		// _.carousel.on('trackPositionChanged', _.handlers.trackPositionChanged);
		// _.carousel.on('slideClicked', _.handlers.slideClicked);
		// _.carousel.on('selectedIndexChanged', _.handlers.selectedIndexChanged);
		// _.carousel.on('displayIndexChanged', _.handlers.displayIndexChanged);
		_.carousel.on('slidePositionsChanged', _.handlers.slidePositionsChanged);
	}

	/**
	 * unbind events from the carousel and perform cleanup
	 */
	destroy() {
		const _ = this;
		_.carousel.off('optionsChanged', _.handlers.optionsChanged);
		// _.carousel.off('userInteracted', _.handlers.userInteracted);
		// _.carousel.off('pageChanged', _.handlers.pageChanged);
		// _.carousel.off('willTransition', _.handlers.willTransition);
		// _.carousel.off('didTransition', _.handlers.didTransition);
		// _.carousel.off('carouselHasFocus', _.handlers.carouselHasFocus);
		// _.carousel.off('carouselLostFocus', _.handlers.carouselLostFocus);
		// _.carousel.off('windowHasFocus', _.handlers.windowHasFocus);
		// _.carousel.off('windowLostFocus', _.handlers.windowLostFocus);
		_.carousel.off('windowResize', _.handlers.windowResize);
		_.carousel.off('windowOrientationChanged', _.handlers.windowOrientationChanged);
		// _.carousel.off('trackPositionChanged', _.handlers.trackPositionChanged);
		// _.carousel.off('slideClicked', _.handlers.slideClicked);
		// _.carousel.off('selectedIndexChanged', _.handlers.selectedIndexChanged);
		// _.carousel.off('displayIndexChanged', _.handlers.displayIndexChanged);
		_.carousel.off('slidePositionsChanged', _.handlers.slidePositionsChanged);
	}
}
