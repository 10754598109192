/*
	This will sync with the other carousel when the selectedIndex changes
*/
export default class AsNavFor {
	/**
	 * @constructor
	 * @param {Object} carousel - the carousel instance that will be synced with another
	 */
	constructor(carousel) {
		const _ = this;
		_.carousel = carousel;
		_.options = carousel.options;
		_.otherCarousel = null;

		// define all event handlers in one object
		_.handlers = {
			// handler for other carousel's willTransition event
			otherWillTransition: (prevIndex, nextIndex) => {
				// return if we are already going to that index or already at it
				if (_.carousel.displayIndex === nextIndex) {
					return;
				}
				// go to same display index as other carousel
				setTimeout(() => {
					_.carousel.goToSlide(nextIndex);
				}, 1);
			},

			// handler for carousel's optionsChanged event
			optionsChanged: () => {
				// console.log('AsNavFor: options changed');
				_.reInit();
			},

			// handler for carousel's selectedIndexChanged event
			selectedIndexChanged: () => {
				// console.log('AsNavFor; selected slide changed');
				_.updateOtherCarousel();
			},
		};

		_.init();
		_.bindEvents();
	}

	/**
	 * initialize the sync functionality
	 */
	init() {
		const _ = this;
		const otherCarousel = document.querySelector(_.options.asNavFor);
		// can't find it
		if (!otherCarousel) return;

		_.otherCarousel = otherCarousel;

		// give DOM a few ms to load the custom elements
		setTimeout(() => {
			_.otherCarousel.on('willTransition', _.handlers.otherWillTransition);
		}, 40);
	}

	/**
	 * reinitialize on carousel option changes
	 */
	reInit() {
		// implementation as needed
		// if (this.carousel.options.autoplay) {
		//   this.start();
		// } else {
		//   this.stop();
		// }
	}

	/**
	 * bind carousel events to handlers
	 */
	bindEvents() {
		const _ = this;
		// bind to carousel events
		_.carousel.on('optionsChanged', _.handlers.optionsChanged);
		_.carousel.on('selectedIndexChanged', _.handlers.selectedIndexChanged);
	}

	/**
	 * query for the other carousel if needed
	 */
	queryOtherCarousel() {
		// implementation as needed
	}

	/**
	 * connect to the other carousel if needed
	 */
	connectToCarousel() {
		// implementation as needed
	}

	/**
	 * update the other carousel to match this one
	 */
	updateOtherCarousel() {
		const _ = this;
		// return if we don't have a carousel to update
		if (!_.otherCarousel) return;

		// tell carousel to go to the same display index
		// async hack
		setTimeout(() => {
			_.otherCarousel.goToSlide(_.carousel.displayIndex);
		}, 1);
	}

	/**
	 * destroy the sync, unbinding all events
	 */
	destroy() {
		const _ = this;

		// unbind from carousel events
		_.carousel.off('optionsChanged', _.handlers.optionsChanged);
		_.carousel.off('selectedIndexChanged', _.handlers.selectedIndexChanged);

		// unbind from other carousel events
		if (_.otherCarousel) {
			_.otherCarousel.off('willTransition', _.handlers.otherWillTransition);
		}

		_.otherCarousel = null;
	}
}
