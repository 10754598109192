import PhysicsEngine from '../../library/physics-engine.js';

export default class RippleWindow {
	#value = 0;
	#maxValue = 0;

	constructor(carousel, mode) {
		const _ = this;
		_.carousel = carousel;
		_.mode = mode;
		_.viewport = carousel.viewport;
		_.track = carousel.track;

		_.isSettling = false;

		_.engine = new PhysicsEngine({
			attraction: _.carousel.options.animation.attraction,
			friction: _.carousel.options.animation.friction,
		});

		// set up handler functions for events
		_.handlers = {
			// handler for reInit
			reInit: () => _.reInit(),

			// handler for engine track position changed events
			enginePositionChanged: ({ position, progress, positionDelta }) => {
				// add directly to value
				_.#value += positionDelta;

				// make sure we stay within bounds
				if (_.#value < 0) {
					_.#value = 0;
				} else if (_.#value > _.#maxValue) {
					_.#value = _.#maxValue;
				}

				// request another render with the new value
				_.carousel._requestModeRender({ animationType: 'settle' });

				// check to see if we can stop the animation
				if (_.#value === 0 || _.#value === _.#maxValue || progress == 1) {
					_.engine.stop();
					_.isSettling = false;
				}
			},
		};
	}

	init() {
		const _ = this;
		_.bindEvents();
		_.reInit();
	}

	reInit() {
		const _ = this;
		_.options = _.carousel.options;
		_.viewportWidth = _.carousel.viewportWidth;
		_.gapWidth = _.carousel.gapWidth;
		_.slideWidth = _.carousel.slideWidth;
		_.slideAndGapWidth = _.slideWidth + _.gapWidth;
		_.trackStartPos = _.carousel.paddingLeftWidth;

		// we stop tracking after end position
		_.trackEndPos =
			_.viewportWidth - _.carousel.trackWidth + _.gapWidth - _.carousel.paddingRightWidth;
	}

	bindEvents() {
		const _ = this;
		_.carousel.on('windowResize', _.handlers.reInit);
		_.carousel.on('optionsChanged', _.handlers.reInit);
		_.engine.on('enginePositionChanged', _.handlers.enginePositionChanged);
	}

	setMaxValue(maxValue) {
		this.#maxValue = maxValue;
	}

	addAmount(amount, trackPos = 0) {
		const _ = this;

		// if we aren't looping
		if (!_.options.loop) {
			// track start of track reached
			if (trackPos > _.trackStartPos) return;
			// end of track reached
			if (trackPos < _.trackEndPos) return;
		}

		// not looping & transitiong back to first slide
		if (!_.options.loop && amount > 0 && trackPos > -1 * _.carousel.slideWidth) {
			// add delta amount to value
			_.#value -= amount;

			// not looping & transitioning to last slide
		} else if (!_.options.loop && amount < 0 && trackPos < _.trackEndPos + _.slideAndGapWidth) {
			_.#value -= amount;

			// default - add delta amount to value
		} else {
			_.#value += amount;
		}

		// make sure we stay within bounds
		if (_.#value < 0) {
			_.#value = 0;
		} else if (_.#value > _.#maxValue) {
			_.#value = _.#maxValue;
		}
		return _.#value;
	}

	getValue() {
		s;
		return this.#value;
	}

	getPercent() {
		return this.#value / this.#maxValue;
	}

	snapToValue() {
		const _ = this;

		// exit if we're already settling
		if (_.isSettling) return;

		// arleady snapped at value
		if (_.#value === 0 || _.#value === _.#maxValue) return;

		_.isSettling = true;

		if (_.#value > _.#maxValue / 2) {
			// tell engine to animate to max value
			_.engine.animateTo(0, _.#maxValue * 0.5, 0);
			return;
		}

		// tell engine to animate to 0
		_.engine.animateTo(_.#maxValue * 0.5, 0, 0);
	}

	destroy() {
		const _ = this;
		// unbind handlers
		_.carousel.off('windowResize', _.handlers.reInit);
		_.carousel.off('optionsChanged', _.handlers.reInit);
		_.engine.off('enginePositionChanged', _.handlers.enginePositionChanged);

		// optionally, destroy the engine if it supports it
		if (_.engine.destroy) _.engine.destroy();

		// clear all references for garbage collection
		_.carousel = null;
		_.mode = null;
		_.viewport = null;
		_.track = null;
		_.engine = null;
		_.handlers = null;
		_.engine = null;
	}
}
