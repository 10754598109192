export default class LazyLoad {
	constructor(carousel) {
		const _ = this;
		_.carousel = carousel;

		// Save handlers for later removal
		_.handlers = {
			userInteracted: () => {
				_.checkLazyImages();
				// Remove this handler after first interaction since we only need to run it once
				_.carousel.off('userInteracted', _.handlers.userInteracted);
			},
			slidesChanged: () => {
				_.reInit();
			},
		};

		_.init();
	}

	init() {
		const _ = this;
		_.slides = _.carousel.slides;
		_.bindEvents();
	}

	reInit() {
		const _ = this;
		_.slides = _.carousel.slides;
		_.checkLazyImages();
	}

	bindEvents() {
		const _ = this;

		// Load images when user interacts with the carousel
		_.carousel.on('userInteracted', _.handlers.userInteracted);

		// Rebind when slides change
		_.carousel.on('slidesChanged', _.handlers.slidesChanged);
	}

	checkLazyImages() {
		const _ = this;
		const slides = _.slides;

		// Guard against slides being undefined or not having a length property
		if (!slides || typeof slides.length === 'undefined') {
			console.warn('LazyLoad: Slides not available');
			return;
		}

		let images;

		// remove all loading="lazy" attributes from images
		// to force them to load
		for (let i = 0, n = slides.length; i < n; ++i) {
			if (!slides[i]) continue;

			images = slides[i].querySelectorAll('img[loading="lazy"]');

			for (let k = 0, l = images.length; k < l; ++k) {
				images[k].removeAttribute('loading');
			}
		}
	}

	destroy() {
		const _ = this;

		// Remove all event listeners
		_.carousel.off('userInteracted', _.handlers.userInteracted);
		_.carousel.off('slidesChanged', _.handlers.slidesChanged);
	}
}
