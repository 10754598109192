import { debounce } from '../utilities/toolkit';

/**
 * controls automatic play/advance of the carousel
 */
export default class Autoplay {
	/**
	 * @constructor
	 * @param {Object} carousel - the carousel instance to control autoplay for
	 */
	constructor(carousel) {
		const _ = this;
		_.carousel = carousel;
		_.autoplayTimer = null;
		_.autoplayOptions = _.carousel.options.autoplay || {};

		// create debounced resume function
		_.debouncedResume = debounce(_.start.bind(_), 10000);

		// define all event handlers in one object
		_.handlers = {
			// handler for carousel's userInteracted event
			userInteracted: () => {
				_.pause();
			},

			// handler for carousel's optionsChanged event
			optionsChanged: () => {
				_.autoplayOptions = _.carousel.options.autoplay || {};
				_.reInit();
			},

			// handler for the interval timer
			tick: () => {
				// exit if document doesn't have focus
				if (!document.hasFocus()) {
					return;
				}
				// go to next page
				_.carousel.next();
			},
		};

		_.bindEvents();
		_.reInit();
	}

	/**
	 * reinitialize on carousel option changes
	 */
	reInit() {
		const _ = this;

		// reload options
		_.autoplayOptions = _.carousel.options.autoplay || {};

		// check options
		if (_.autoplayOptions.interval) {
			_.start();
		} else {
			_.stop();
		}
	}

	/**
	 * bind carousel events to handlers
	 */
	bindEvents() {
		const _ = this;
		// pause when user interacts with carousel
		_.carousel.on('userInteracted', _.handlers.userInteracted);
		_.carousel.on('optionsChanged', _.handlers.optionsChanged);
	}

	/**
	 * start the autoplay timer
	 */
	start() {
		const _ = this;
		// clear existing timer
		_.stop();

		// exit if it's turned off
		if (!_.autoplayOptions.interval) return;

		let interval = _.autoplayOptions.interval;
		if (interval === 0 || interval === false) return;
		if (interval === true) interval = 4000;

		_.autoplayTimer = setInterval(_.handlers.tick, interval);
	}

	/**
	 * stop the autoplay timer
	 */
	stop() {
		const _ = this;
		clearInterval(_.autoplayTimer);
		_.autoplayTimer = null;
	}

	/**
	 * pause autoplay temporarily
	 */
	pause() {
		const _ = this;
		// stops the current timer
		_.stop();

		// restarts in 10 seconds if no interaction
		if (_.autoplayOptions.interval) {
			_.debouncedResume();
		}
	}

	/**
	 * destroy the autoplay, unbinding all events and clearing timers
	 */
	destroy() {
		const _ = this;

		// stop any running timer
		_.stop();

		// unbind from carousel events
		_.carousel.off('userInteracted', _.handlers.userInteracted);
		_.carousel.off('optionsChanged', _.handlers.optionsChanged);

		// clear the debounced function
		if (_.debouncedResume.cancel) {
			_.debouncedResume.cancel();
		}
	}
}
