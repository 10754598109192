import { debounce } from '../utilities/toolkit.js';

const SHIFTED_STATE = {
	START: -1,
	NONE: 0,
	END: 1,
};

export default class LoopManager {
	#handlers;

	constructor(carousel) {
		const _ = this;
		_.carousel = carousel;

		_.track = carousel.track;

		_.currentState = SHIFTED_STATE.NONE;

		// handlers for binding and unbinding
		_.#handlers = {
			reInit: debounce(() => _.reInit(), 5),
			trackPositionChanged: ({ newPosition }) => {
				// console.log('loop manager pos changed', newPosition);
				_.updateLoop(newPosition);
			},
		};

		_.wrapOffset = 0;

		_.bindEvents();
		_.init();
	}

	init() {
		this.reInit();
	}

	reInit() {
		const _ = this;

		// copy slides
		_.slides = _.carousel.slides;

		// copy options
		_.options = _.carousel.options;
		_.slidesVisible = _.options.slidesVisible;
		_.slidesPerMove = _.options.slidesPerMove;

		// copy widths
		_.viewportWidth = _.carousel.viewport.offsetWidth;
		_.slideWidth = _.carousel.slideWidth;

		// calculate some stuff
		_.canLoop = _.canWeLoop();
		_.wrapOffset = _.calculateWrapBuffer();

		// we can't loop anymore so reset it
		if (!_.canLoop && _.currentState !== SHIFTED_STATE.NONE) {
			_.wrapReset();
		}
	}

	bindEvents() {
		const _ = this;
		// Bind events with stored handlers
		_.carousel.on('optionsChanged', _.#handlers.reInit);
		_.carousel.on('slidesChanged', _.#handlers.reInit);
		_.carousel.on('windowResize', _.#handlers.reInit);
		_.carousel.on('trackPositionChanged', _.#handlers.trackPositionChanged);
	}

	// TODO: implement this somewhere during mode changes
	// setRenderIndex is used to position slides
	// loopmanager changes this to shift slides on the track
	resetSlideRenderIndexes() {
		// console.log('\n\nLOOP MANAGER: resetSlideRenderIndexes');
		const slides = this.carousel.slides;
		for (let i = 0, n = slides.length; i < n; ++i) {
			slides[i].setRenderIndex(i);
		}
	}

	canWeLoop() {
		return this.options.loop && this.slides.length > this.slidesVisible;
	}

	/* 
		We use _.wrapOffset to try and wrap the slides to the ends
		before we get there to help create a seamless effect.
		This is especially important for the ripple effect where
		slides peek in from the edges and we need them there.
		This works best when we have enough slides in the carousel
		so we can buffer earlier. 
	*/
	calculateWrapBuffer() {
		const _ = this;

		// try to ask for a larger buffer
		if (_.slides.length > _.slidesVisible + 3) {
			// console.log('returning loop buffer - slideWidth', _.slideWidth);
			// buffer full slide width
			return _.slideWidth;
		} else if (_.slides.length > _.slidesVisible + 2) {
			// console.log('returning loop buffer - HALF slideWidth', _.slideWidth);
			// buffer half slide width
			return _.slideWidth / 2;
		}

		// console.log('returning loop buffer 0');
		return 0;
	}

	updateLoop(trackPos) {
		// check to see if we can loop
		if (!this.canWeLoop()) {
			// reset and exit
			this.wrapReset();
			return;
		}

		// might have to try something like this for center mode
		// trackPos += _.viewportWidth / 2;
		// trackPos -= _.slideWidth / 2;

		// check for track shift and wrap
		this.checkShift(trackPos);
	}

	checkShift(trackPos) {
		const _ = this;
		const trackWidth = _.carousel.trackWidth;

		// const startTrigger = _.carousel.paddingLeftWidth;
		const startTrigger = 0;
		const endTrigger = trackWidth * -1;

		// console.log('\n                  [ CHECK SHIFT ]         carousel: ', _.carousel.id);
		// console.log('\ntrackPos: ', trackPos);

		// console.log(' ' + trackPos + '    >      ' + startTrigger);
		// console.log('trackPos > startTrigger', trackPos > startTrigger);
		// console.log(' ' + trackPos + '    <=      ' + endTrigger);
		// console.log('(trackPos <= endTrigger', trackPos <= endTrigger);

		// console.log('endTrigger: ', endTrigger);

		// looping from front to back
		if (trackPos > startTrigger) {
			// console.log('\n                            >>>>>>>>>>>>>>>>>>>>>>>>>>>>>  SHIFT END');

			// tell track animater to shift track
			_.carousel._shiftTrackForwards();

			// shift to back
			_.wrapEnd();

			// console.log('wrap End');
			return;
		}

		if (trackPos <= endTrigger) {
			// console.log('\n                            <<<<<<<<<<<<<<<<<<<<<<<<<<<<<  SHIFT START');

			// tell track animater to shift track
			_.carousel._shiftTrackBackwards();

			// shift to front
			_.wrapStart();

			return;
		}

		// console.log('NO SHIFT - CHECK WRAP');

		_.checkWrap(trackPos);
	}

	checkWrap(trackPos) {
		const _ = this;
		const trackWidth = _.carousel.trackWidth;

		const startTrigger = _.carousel.paddingLeftWidth - _.wrapOffset;
		const endTrigger = (trackWidth - _.viewportWidth - _.wrapOffset) * -1;

		// const startTrigger = 0;
		// const endTrigger = (trackWidth - _.viewportWidth) * -1;

		// console.log('wrapOffset', _.wrapOffset);

		// console.log('\n                  [ CHECK WRAP ]');
		// console.log(' ' + trackPos + '    >      ' + startTrigger);
		// console.log('trackPos > startTrigger', trackPos > startTrigger);
		// console.log(' ' + trackPos + '    <=     ' + endTrigger);
		// console.log('(trackPos <= endTrigger', trackPos <= endTrigger);

		// wrap front
		if (trackPos > startTrigger) {
			_.wrapStart();
			return;
		}

		// wrap end
		if (trackPos <= endTrigger) {
			_.wrapEnd();
			return;
		}

		// reset wraps
		if (trackPos <= startTrigger && trackPos > endTrigger) {
			_.wrapReset();
		}
	}

	// wrap moves the slides from the end to the start
	wrapStart() {
		const _ = this;

		// already shifted to start
		if (_.currentState == SHIFTED_STATE.START) return;

		// console.log('\n\n                              ~~~~~~~  WRAP START  ~~~~~~~  \n\n');

		const slides = _.carousel.slides;
		let slidesVisible = _.slidesVisible;
		const slideCount = slides.length;

		if (slidesVisible < slideCount - slidesVisible - 2) {
			slidesVisible = slidesVisible + 2;
		}

		// calculate render index
		for (let i = 0; i < slideCount; ++i) {
			if (i < slideCount - slidesVisible) {
				// set index to normal
				slides[i].setRenderIndex(i);
			} else {
				// set render index to shifted state
				slides[i].setRenderIndex(i - slideCount);
			}
		}

		// wrap end slides to the beginning
		_.currentState = SHIFTED_STATE.START;
		_.carousel.emit('slidePositionsChanged');
	}

	// wrap moves the slides from the start to the end
	wrapEnd() {
		const _ = this;

		// already shifted to end
		if (_.currentState == SHIFTED_STATE.END) return;

		// console.log('\n\n                              ~~~~~~~  WRAP END  ~~~~~~~  \n\n');

		const slides = _.carousel.slides;
		let slidesVisible = _.slidesVisible;
		const slideCount = slides.length;

		// add extra slide if we can
		if (slidesVisible < slideCount - slidesVisible - 2) {
			slidesVisible = slidesVisible + 2;
		}

		// calculate render index
		for (let i = 0; i < slideCount; ++i) {
			if (i < slidesVisible) {
				slides[i].setRenderIndex(i + slideCount);
			} else {
				// set index to normal
				slides[i].setRenderIndex(i);
			}
		}

		_.currentState = SHIFTED_STATE.END;
		_.carousel.emit('slidePositionsChanged');
	}

	wrapReset() {
		const _ = this;

		// always reset render index just in case
		_.resetSlideRenderIndexes();

		// exit if already reset
		if (_.currentState === SHIFTED_STATE.NONE) return;

		// console.log('\n\n                              ~~~~~~~  WRAP RESET  ~~~~~~~  \n\n');

		_.currentState = SHIFTED_STATE.NONE;
		_.carousel.emit('slidePositionsChanged');
	}

	destroy() {
		const _ = this;

		_.carousel.off('optionsChanged', _.#handlers.reInit);
		_.carousel.off('slidesChanged', _.#handlers.reInit);
		_.carousel.off('windowResize', _.#handlers.reInit);
		_.carousel.off('trackPositionChanged', _.#handlers.trackPositionChanged);

		// Reset wrap state
		_.wrapReset();
	}
}
