import LoopManager from './loop-manager.js';

/**
 * Manages track animator state
 * Converts slide indexes to track positions
 * Tells animator to animate or jump to new position
 * Relays and emits events back to the carousel
 *
 **/
export default class TrackManager {
	constructor(carousel, animator) {
		const _ = this;

		_.carousel = carousel;
		_.track = carousel.track;
		_.viewport = carousel.viewport;
		_.animator = animator;

		_.handlers = {
			displayIndexChanged: ({ oldIndex, newIndex, velocity, animationType = 'animate' }) => {
				// I can't find a better place for this because
				// all the data is right here so it's easy to pass on
				_.carousel.emit('willTransition', { oldIndex, newIndex, animationType });
				// go to slide
				_.animateToSlide(newIndex, velocity, animationType);
			},
		};

		_.bindEvents();
	}

	init() {}

	reInit() {}

	bindEvents() {
		const _ = this;
		_.carousel.on('displayIndexChanged', _.handlers.displayIndexChanged);
	}

	requestTrackAnimation({ oldIndex, newIndex, velocity, animationType = 'animate' }) {
		// console.log('requestTrackAnimation');
	}

	// settling track happens when the track has moved
	// but the dragThreshold hasn't been reached
	// this can be used in the mode render for certain animations
	settleTrack() {
		this.animateToSlide(this.carousel.displayIndex, 0, 'settle');
	}

	// calculates position on track and tells animator to go
	animateToSlide(slideIndex, velocity, animationType) {
		const _ = this;
		const currentPos = _.animator.currentPos;
		let newPos = _.getTrackPosForIndex(slideIndex);
		const trackWidth = _.carousel.trackWidth;

		// no one ever makes the first jump
		if (animationType === 'jump') {
			_.animator.animateToPosition(newPos, 0, 'jump');
			return;
		}

		// if we're looping, this will find the fastest path
		if (_.carousel.options.loop) {
			const posDelta = Math.abs(currentPos - newPos);
			// if large distance, shift track position for looping
			if (posDelta > trackWidth / 2) {
				if (newPos > currentPos) {
					newPos -= trackWidth;
				} else {
					newPos += trackWidth;
				}
			}
		}

		// apply simple velocity logic if none was provided
		if (velocity === undefined) {
			velocity = newPos < currentPos ? -15 : 15;
		} else {
			// give it a small boost
			velocity *= 1.2;
			// if too low, boost it again
			if (Math.abs(velocity) < 15) velocity *= 1.3;
		}

		// trigger animation:
		_.animator.animateToPosition(newPos, velocity, animationType);
	}

	// new jumpToSlide function that calls animator.jumpToPosition
	// jumpToSlide(slideIndex, animationType) {
	// 	this.animator.jumpToPosition(this.getTrackPosForIndex(slideIndex), animationType);
	// }

	/**
	 * calculates the track position for a given slide index
	 * @param {number} slideIndex - the index of the slide
	 * @returns {number} the position on the track (negative for transform)
	 */
	getTrackPosForIndex(slideIndex) {
		const _ = this;
		const carousel = _.carousel;
		const options = carousel.options;

		let pos = slideIndex * (carousel.slideWidth + carousel.gapWidth);
		const minPos = 0;
		const maxPos = carousel.trackWidth - carousel.viewportWidth - 0.1;

		// are we centering selected slides?
		if (pos != minPos && pos != maxPos && options.centerSelectedSlide) {
			// center selected slide
			pos -= carousel.viewportWidth / 2;
			pos += carousel.slideWidth / 2;
		} else {
			// account for left padding
			pos -= carousel.paddingLeftWidth;
		}

		// keep left side in viewport range
		// notice we ignore leftPadding and gapWidth here
		if (!options.loop && pos < 0) {
			pos = minPos;
		}

		// keep right side in viewport range
		// notice we ignore rightPadding here
		// clamp if we are not looping
		if (!options.loop && pos > maxPos) {
			pos = maxPos;
		}

		// transforms are negative
		if (pos !== 0) pos *= -1;

		return pos;
	}
}
