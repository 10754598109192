/**
 * throttle calls a function at most once every `limit` milliseconds
 * @param {Function} callback - function to be throttled
 * @param {number} limit - number of milliseconds to wait before allowing another call
 * @returns {Function} a throttled function
 */
function throttle(callback, limit) {
	var waiting = false;
	return function (...args) {
		if (!waiting) {
			callback.apply(this, args);
			waiting = true;
			setTimeout(function () {
				waiting = false;
			}, limit);
		}
	};
}

/**
 * debounce calls a function after a specified delay has passed since the last time it was invoked.
 * @param {Function} func - the function to debounce
 * @param {number} wait - the number of milliseconds to wait before calling func
 * @param {boolean} [immediate=false] - if true, func is called on the leading edge of the timeout
 * @returns {Function} a debounced function that delays invoking func
 */
function debounce(func, wait, immediate) {
	var timeout;
	return function (...args) {
		var context = this;
		var later = function () {
			timeout = null;
			if (!immediate) {
				func.apply(context, args);
			}
		};
		var callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) {
			func.apply(context, args);
		}
	};
}

/**
 * Deep merge two objects.
 * @param {Object} target - The target object.
 * @param {Object} source - The source object.
 * @returns {Object} - The merged object.
 */
function deepMerge(target, source) {
	const isObject = (obj) => obj && typeof obj === 'object';

	return Object.keys(source).reduce(
		(acc, key) => {
			if (Array.isArray(acc[key]) && Array.isArray(source[key])) {
				acc[key] = acc[key].concat(source[key]);
			} else if (isObject(acc[key]) && isObject(source[key])) {
				acc[key] = deepMerge({ ...acc[key] }, source[key]);
			} else {
				acc[key] = source[key];
			}
			return acc;
		},
		{ ...target }
	);
}

// accepts number (int or float), Pixel value "10px", or percent "10%"
// returns a numerical value
function convertValueToNumber(value, width) {
	if (typeof value == 'number') {
		// if value is a percent between 0 and 1
		if (value > 0 && value < 1) {
			return value * width;
		}
		// else it's just a number
		return value;
	}
	if (value.indexOf('px') > -1) {
		return parseFloat(value.replace('px', ''));
	} else if (value.indexOf('%') > -1) {
		return (parseFloat(value.replace('%', '')) / 100) * width;
	}
	return 0;
}

function areOptionsEqual(obj1, obj2) {
	// Check for reference equality
	if (obj1 === obj2) return true;

	// Check if both are objects
	if (typeof obj1 !== 'object' || obj1 === null || typeof obj2 !== 'object' || obj2 === null) {
		return false;
	}

	// Handle arrays
	if (Array.isArray(obj1) && Array.isArray(obj2)) {
		if (obj1.length !== obj2.length) return false;
		for (let i = 0; i < obj1.length; i++) {
			if (!areOptionsEqual(obj1[i], obj2[i])) {
				return false;
			}
		}
		return true;
	}

	const keys1 = Object.keys(obj1);
	const keys2 = Object.keys(obj2);

	// Check if the number of keys is the same
	if (keys1.length !== keys2.length) return false;

	// Compare keys and their values
	for (const key of keys1) {
		if (!keys2.includes(key) || !areOptionsEqual(obj1[key], obj2[key])) {
			return false;
		}
	}

	return true;
}

export { throttle, debounce, deepMerge, convertValueToNumber, areOptionsEqual };
