export default class CarouselMode {
	static name = 'carousel';

	// TODO: Implement mode rules in option manager
	static rules = {
		slideMinWidth: 0,
		minSlidesVisible: 1,
		maxSlidesVisible: Infinity,
	};

	constructor(carousel) {
		this.carousel = carousel;
		this.init();
	}

	init() {}

	reInit() {}

	/**
	 * position all slides on the track
	 * runs with every animation frame when track is moving
	 * @param {Object} params - render parameters
	 * @param {number} params.trackPosition - current position of the track
	 * @param {number} params.trackDelta - change in position since last frame
	 * @param {number} params.velocity - current velocity of movement
	 * @param {number} params.progress - progress value from 0 to 1
	 * @param {string} params.animationType - type of animation ("jump", "animate", "settle", 'shift')
	 * @returns {void}
	 */
	render({
		slides,
		trackPosition = 0,
		trackDelta = 0,
		velocity = 0,
		progress = 1,
		animationType = 'jump',
	}) {
		const _ = this;
		const carousel = _.carousel;
		const slideAndGapWidth = carousel.slideWidth + carousel.gapWidth;
		let slide;
		// position each slide based on renderIndex
		for (let i = 0, n = slides.length; i < n; ++i) {
			slide = slides[i];
			slide.style.transition = 'none';
			slide.style.transform = `translateX(${slideAndGapWidth * slide.getRenderIndex()}px)`;
		}
	}

	destroy() {
		this.carousel = null;
	}
}
