// import TarotPlugin from './tarot-plugin';
import { getPrevIndex, getNextIndex } from './utilities/mode-toolkit';

export default class FadeMode {
	static name = 'fade';

	constructor(carousel) {
		const _ = this;
		_.name = 'fade';
		_.carousel = carousel;
		_.slides = carousel.slides;
		_.isDragging = false;
		_.dragThreshold = 60;

		_.viewportWidth = 0;

		_.init();
	}

	init() {
		const _ = this;
		const carousel = _.carousel;
		const options = carousel.options;

		// set mode
		carousel.setAttribute('data-mode', _.name);

		_.calculateWidths();

		_.setSelectedSlide();

		_.jumpToSlide(carousel.displayIndex);
	}

	reinitPlugin() {
		const _ = this;
		// recalculate widths
		_.calculateWidths();
		// set prev, current, and next slide
		_.setSelectedSlide();
		// jump to slide
		_.jumpToSlide(_.carousel.displayIndex);
		// console.log('fademode reinit plugin')
	}

	destroy() {
		const _ = this;
		console.log('fademode destroy');
		const slides = _.carousel.slides;
		for (let i = 0, n = slides.length; i < n; ++i) {
			slides[i].style.opacity = null;
			slides[i].classList.remove('is-visible');
			slides[i].classList.remove('is-active');
			slides[i].removeAttribute('aria-hidden');
		}
	}

	resize() {
		console.log('fademode resize', this.carousel.currentOptions);
	}

	calculateWidths() {
		const _ = this;
		// viewport width without gaps
		_.viewportWidth = _.carousel.offsetWidth;
	}

	setSelectedSlide() {
		const _ = this;
		const displayIndex = _.carousel.displayIndex;
		const slides = _.slides;
		// console.log('ss', _.slides)
		_.selectedSlide = _.slides[displayIndex];
		_.prevSlide = _.slides[getPrevIndex(slides, displayIndex)];
		_.nextSlide = _.slides[getNextIndex(slides, displayIndex)];
		// console.log('selected slide', _.selectedSlide)
	}

	dragStart(event, drag) {
		const _ = this;
		// taking a drag
		_.isDragging = true;
		// console.log('fademode dragStart')
	}

	dragMove(event, drag) {
		const _ = this;
		const opacity = 1 - Math.abs(drag.delta / (_.viewportWidth * 0.8));
		_.selectedSlide.style.opacity = opacity;

		if (drag.delta < 0) {
			_.prevSlide.style.opacity = 0;
			_.nextSlide.style.opacity = 1;
		} else {
			_.prevSlide.style.opacity = 1;
			_.nextSlide.style.opacity = 0;
		}
		// console.log('fademode dragMove', drag.delta / _.viewportWidth )
	}

	dragEnd(event, drag) {
		const _ = this;

		// if we didn't hit the threshold
		if (Math.abs(drag.delta) < _.dragThreshold) {
			_.prevSlide.style.opacity = 0;
			_.nextSlide.style.opacity = 0;
			_.selectedSlide.style.opacity = 1;
			return;
		}

		// which direction are we going?
		if (drag.delta < 0) {
			// Go to next
			_.goToNextSlide();
		} else {
			// Go to previous
			_.goToPrevSlide();
		}

		// console.log('fademode dragEnd', drag.delta / _.viewportWidth )
	}

	transitionToSlide(index) {
		const _ = this;
		console.log('fademode transitionToSlide', index);
	}

	goToPrevSlide() {
		const _ = this;
		_.goToSlide(getPrevIndex(_.slides, _.carousel.displayIndex));
	}

	goToNextSlide() {
		const _ = this;
		_.goToSlide(getNextIndex(_.slides, _.carousel.displayIndex));
	}

	jumpToSlide(index) {
		const _ = this;
		// console.log('fade jumpto slide ', index)
		_.carousel.displayIndex = index;

		// _.slides[index].style.transition = "none"
		_.slides[index].style.opacity = 1;
		_.slides[index].style.zIndex = 1;

		_.setSelectedSlide();
	}

	goToSlide(index) {
		const _ = this;
		const carousel = _.carousel;
		// console.log('fade goto slide ', index)

		carousel.prevIndex = carousel.displayIndex;
		carousel.displayIndex = index;
		carousel.selectedIndex = index;

		// this must come after we set the new indexes
		// or it could create a feedback loop

		const currentSlide = _.selectedSlide;
		const nextSlide = _.slides[index];
		carousel.displayIndex = index;

		// show next slide behind current slide
		nextSlide.style.transition = 'none';
		nextSlide.style.opacity = 1;
		// _.slides[index].style.zIndex = 1

		// fade out current slide
		// currentSlide.style.transition = "opacity 300ms ease"
		currentSlide.classList.add('tarot-fade-out');
		currentSlide.style.opacity = 0;

		setTimeout(() => {
			// clear out old slide properties
			currentSlide.classList.remove('tarot-fade-out');
			currentSlide.style.zIndex = null;
			currentSlide.style.opacity = null;

			// next slide is visible
			nextSlide.style.opacity = 1;
			nextSlide.style.zIndex = 1;
			nextSlide.style.transition = null;
		}, 400);

		_.setSelectedSlide();
	}
}
